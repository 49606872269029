import React from 'react';
import { Field } from 'redux-form';
import { InputPhone } from 'components/Form';
import useReduxForm from 'hooks/useReduxForm';
import useFormValue from 'hooks/useFormValue';
// import { Container } from './styles';

const FieldPhone = ({
  label = 'Telefone',
  name = 'cellphone',
  nameDDI = 'ddi',
  nameISO2 = 'iso2',
  ...props
}) => {
  const iso2 = useFormValue(nameISO2);
  const { change, dispatch } = useReduxForm();

  return (
    <Field
      defaultCountry={iso2 || 'br'}
      label={label}
      name={name}
      component={InputPhone}
      onSelectFlag={(status, countryData) => {
        dispatch(change(nameDDI, countryData.dialCode));
        dispatch(change(nameISO2, countryData.iso2));
      }}
      {...props}
    />
  );
};

export default FieldPhone;
