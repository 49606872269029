import classnames from 'classnames';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { MdInfo } from 'react-icons/md';
import { Field } from 'redux-form';
// Components
import Alert from 'components/Alert';
import {
  FieldBool,
  Input,
  Select,
  SelectMultiple,
  ShowErrors
} from 'components/Form';
import FormBox from 'components/FormBox';
import GroupControl, { GroupItem } from 'components/GroupControl';
// Containers
import FieldPerson from 'containers/FieldPerson';
// Helpers
import { parseSelect } from 'lib/formHelpers';
// Options
import { NEAR_SEA, PROFILE } from 'constants/options';
import FieldSituation from 'containers/FieldSituation';

const propTypes = {};
const defaultProps = {};

class StickedOption extends React.PureComponent {
  handleMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onSelect(this.props.option, event);
  };
  handleMouseEnter = (event) => {
    this.props.onFocus(this.props.option, event);
  };
  handleMouseMove = (event) => {
    if (this.props.isFocused) return;
    this.props.onFocus(this.props.option, event);
  };

  get className() {
    const { option, className } = this.props;
    return classnames('help', className, {
      'Select--isGroup': option.is_type,
    });
  }

  render() {
    return (
      <div
        className={this.className}
        onMouseDown={this.handleMouseDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseMove={this.handleMouseMove}
        title={this.props.option.title}
      >
        {this.props.children}
      </div>
    );
  }
}

// const HasExpiryDateTitle = (
//   <>
//     Definir Validade?
//     <span
//       data-tip="O Perfil será desativado automaticamente na data desejada."
//       className="h-margin-left--5"
//     >
//       <MdInfo />
//     </span>
//   </>
// );

const WithCondoTitle = (
  <>
    Considerar condomínio
    <span
      data-tip="Considerar preço + valor<br/>do condomínio"
      className="h-margin-left--5"
    >
      <MdInfo />
    </span>
  </>
);

const LabelNetwork = () => (
  <>
    Procurar na rede Guru?
    <span
      data-tip={`
      Com a rede, você terá a disposição <br/>
      todos os imóveis cadastrados por<br/> 
      outros corretores na sua região.
    `}
      className="h-margin-left--5"
    >
      <MdInfo />
    </span>
  </>
);

const Info = ({
  isSearching,
  transaction,
  typeSelected,
  typesAndSubtypes,
  isFocusMinPrice,
  isFocusMaxPrice,
  handleFocusMinPrice,
  handleFocusMaxPrice,
  handleChangeTypeOrSubtype,
  change,
}) => {
  useEffect(() => {
    if (transaction === 1) {
      change('with_condo_price', null);
    } else {
      change('with_condo_price', false);
    }
  }, [transaction]);

  return (
    <FormBox
      styleHeader={{ width: '100%' }}
      title={
        <>
          <div>Informações</div>

          {!isSearching && (
            <Alert
              color="secondaryLight"
              style={{
                width: '100%',
                marginTop: 15,
                marginBottom: -15,
                fontWeight: '400',
              }}
            >
              Caso queira ignorar alguma dessas características, basta deixar o
              campo em branco e ele não será levado em conta.
            </Alert>
          )}
        </>
      }
    >
      <Row>
        {!isSearching && (
          <FieldPerson
            xs={3}
            label="Cliente"
            name="people_id"
            onCreatePerson={(person) => {
              change('people_id', person.id);
            }}
          />
        )}
        <Field
          multi={false}
          xs={3}
          label="Tipo/Subtipo do imóvel"
          name="type_or_subtype_id"
          component={SelectMultiple}
          valueKey="id"
          labelKey="title"
          options={typesAndSubtypes}
          onChange={(...rest) => {
            handleChangeTypeOrSubtype(...rest);
          }}
          parse={parseSelect('id')}
          optionComponent={StickedOption}
          optionRenderer={(option) => {
            if (option.is_type) {
              return (
                <div
                  style={{
                    padding: 12,
                    margin: '-8px -10px',
                    background: '#f3f6fa',
                  }}
                >
                  {option.title}
                </div>
              );
            }

            return (
              <div className={option.is_subtype && 'h-margin-left--15'}>
                {option.title}
              </div>
            );
          }}
        />
        <Field
          xs={3}
          label="Perfil do imóvel"
          name="profiles"
          component={SelectMultiple}
          options={PROFILE}
          parse={parseSelect('value')}
        />
        <FieldSituation
          name="situations_id"
          required={false}
          multi
          typeId={typeSelected?.type_id}
          xs={3}
        />
        <Field
          buttonTemplate
          xs={3}
          name="transaction"
          label="Transação"
          component={Select}
          options={[
            { label: 'Venda', value: 1 },
            { label: 'Aluguel', value: 2 },
            { label: 'Temporada', value: 3 },
          ]}
          format={(value) => {
            if (!value) return;
            return parseInt(value, 10);
          }}
        />
        {transaction === 2 && (
          <FieldBool
            buttonTemplate
            xs={3}
            name="with_condo_price"
            label={WithCondoTitle}
            component={Select}
          />
        )}
        <Col xs={3}>
          <GroupControl label={`Preço (Mín. e Máx)`}>
            <Field type="hidden" name="name" component="input" />
            <Row className="row--5">
              <Col xs={isFocusMinPrice ? 8 : isFocusMaxPrice ? 4 : 6}>
                <GroupItem>
                  <Field
                    hideError
                    valueFormat
                    name="minimum_price"
                    component={Input}
                    placeholder={'Indiferente'}
                    onFocus={handleFocusMinPrice(true)}
                    onBlur={handleFocusMinPrice(false)}
                  />
                </GroupItem>
              </Col>
              <Col xs={isFocusMaxPrice ? 8 : isFocusMinPrice ? 4 : 6}>
                <GroupItem>
                  <Field
                    hideError
                    valueFormat
                    name="maximum_price"
                    component={Input}
                    placeholder={'Indiferente'}
                    onFocus={handleFocusMaxPrice(true)}
                    onBlur={handleFocusMaxPrice(false)}
                  />
                </GroupItem>
              </Col>
            </Row>
          </GroupControl>
          <ShowErrors fields={['maximum_price']} />
        </Col>
        <FieldBool
          xs={3}
          label="Minha Casa Minha Vida?"
          name="is_financeable_mcmv"
          canRemoveSelf
        />
        {!isSearching && (
          <>
            <FieldBool xs={3} label={<LabelNetwork />} name="is_on_network" />
          </>
        )}

        <Field
          xs={3}
          label="Próximo ao mar?"
          name="near_sea"
          component={Select}
          options={NEAR_SEA}
        />
        <FieldBool
          xs={3}
          label="Averbado"
          name="is_property_titled"
          canRemoveSelf
        />
        <FieldBool xs={3} label="Escriturado" name="is_deeded" canRemoveSelf />
        <FieldBool
          canRemoveSelf
          xs={3}
          buttonTemplate
          name="is_exchangeable"
          label="Aceita permuta?"
        />
      </Row>
      <Row>
        <FieldBool
          canRemoveSelf
          xs={3}
          label="Tem mobília"
          name="has_furniture"
        />
      </Row>
    </FormBox>
  );
};

Info.propTypes = propTypes;
Info.defaultProps = defaultProps;

export default Info;
