import Wrapper from 'components/Wrapper';
import { useRequest } from 'hooks/useRequest';
import Filter from './components/Filter';
import List from './components/List';
import { getList } from 'services/condos';
import { useEffect, useState } from 'react';

function PropertiesCondos() {
  const [params, setParams] = useState({});

  const { data, meta, fetchData, isFetching } = useRequest({
    request: (requestParams) =>
      getList({
        offset: 1,
        limit: 20,
        sort: 'title',
        include: 'neighborhood.city,neighborhood.city.state',
        count:
          'available_properties,sold_properties,rented_properties,excluded_properties',
        ...requestParams,
      }),
    initialFetch: false,
  });

  useEffect(() => fetchData(params), [params]);

  const handleChangePage = (page) => {
    setParams((prev) => ({ ...prev, offset: page.selected + 1 }));
  };

  const handleChangeFilter = (params) => {
    setParams((prev) => ({
      ...prev,
      filter: { ...params },
    }));
  };

  return (
    <Wrapper full>
      <Filter onSubmit={handleChangeFilter} />
      <List
        data={data}
        pagination={meta?.pagination}
        isFetching={isFetching}
        handleChangePage={handleChangePage}
        fetchData={fetchData}
      />
    </Wrapper>
  );
}

export default PropertiesCondos;
