import FieldGroundRange from 'components/FieldGroundRange';
import { FieldBool } from 'components/Form';
import * as Filters from 'components/PropertyFilters';
import FieldMultiCharacteristics from 'containers/FieldMultiCharacteristics';
import FieldMultiCondoCharacteristics from 'containers/FieldMultiCondoCharacteristics';
import Rooms from 'containers/ModalRoot/ModalPropertySearch/components/Rooms';
import { useModalPropertySearch } from 'containers/ModalRoot/ModalPropertySearch/context';
import useFormValue from 'hooks/useFormValue';
import useReduxForm from 'hooks/useReduxForm';
import {
  currentTypeSelector,
  isSellTransaction,
  isTypeGroundSelector,
  receiveTypes,
  typeOrSubtypeIdSelector,
} from 'modules/modalPropertySearch';
import { Col, Row } from 'react-flexbox-grid';
import { useDispatch, useSelector } from 'react-redux';
import Nolocation from '../Nolocation';
import { closeModal } from 'modules/modal';

// import { Wrapper } from './styles';

function MainFields() {
  const { handleUpdateCount } = useModalPropertySearch();
  const condomoniumId = useFormValue('condominium_id');
  const transaction = useFormValue('transaction');

  const { change } = useReduxForm();
  const dispatch = useDispatch();
  const isSell = useSelector(isSellTransaction);
  const typeOrSubtypeId = useSelector(typeOrSubtypeIdSelector);
  const currentType = useSelector(currentTypeSelector);
  const isGround = useSelector(isTypeGroundSelector);

  const clearFields = () => {
    dispatch(change('by_condos_characteristic_id', null));
    dispatch(change('by_characteristic_id', null));
    dispatch(change('situation_id', null));
    dispatch(change('is_exchangeable', null));
    dispatch(change('is_corner', null));
    dispatch(change('has_furniture', null));
    dispatch(change('price', null));
    dispatch(change('price.with_rental_taxes', true));
  };

  const onLoadTypeAndSubtype = (types) => {
    dispatch(receiveTypes(types));
  };

  const handleChangeTransaction = () => {
    clearFields();
    dispatch(change('is_season_available', null));
    handleUpdateCount();
  };

  const handleChangeTypeAndSubtype = (typeOrSubtype, ...props) => {
    const typeName = typeOrSubtype?.type?.title || typeOrSubtype?.title;
    dispatch(change('type_name', typeName));
    dispatch(change('by_area', {}));
    dispatch(change('by_room', {}));
    clearFields();
    handleUpdateCount();
  };

  const handleEsc = (e) => {
    if (e.keyCode === 27 || e.keyCode === '27') {
      dispatch(closeModal());
    }
  };

  return (
    <Row>
      <Col xs={3}>
        <Filters.TypeAndSubtype
          onChange={handleChangeTypeAndSubtype}
          onLoad={onLoadTypeAndSubtype}
          onInputKeyDown={handleEsc}
        />
        <Filters.Transaction canRemoveSelf onChange={handleChangeTransaction} />
        {!condomoniumId ? (
          <Filters.NewLocation onChange={handleUpdateCount} />
        ) : (
          <Nolocation />
        )}
      </Col>
      <Col xs={3}>
        <Rooms onChange={handleUpdateCount} />
        <Filters.Situations
          typeOrSubtypeId={typeOrSubtypeId}
          onChange={handleUpdateCount}
        />
      </Col>

      <Col xs={3}>
        <Filters.PriceFieldRent
          onBlur={handleUpdateCount}
          typeSaleOrRent={transaction}
        />
        <FieldGroundRange type={currentType} onBlur={handleUpdateCount} />
        {isGround ? (
          <FieldBool
            onChange={handleUpdateCount}
            canRemoveSelf
            name="is_corner"
            label="Esquina"
          />
        ) : (
          <FieldBool
            onChange={handleUpdateCount}
            canRemoveSelf
            name="has_furniture"
            label="Mobiliado"
          />
        )}
        <FieldBool
          canRemoveSelf
          buttonTemplate
          name="is_exchangeable"
          label="Aceita permuta?"
          onChange={handleUpdateCount}
        />
      </Col>

      <Col xs={3}>
        {isSell ? (
          <FieldBool
            onChange={handleUpdateCount}
            canRemoveSelf
            buttonTemplate
            name="is_financeable"
            label="Aceita financiamento?"
          />
        ) : (
          <FieldBool
            onChange={handleUpdateCount}
            canRemoveSelf
            buttonTemplate
            name="is_season_available"
            label="Temporada?"
          />
        )}
        {isSell && (
          <FieldBool
            onChange={handleUpdateCount}
            canRemoveSelf
            buttonTemplate
            name="is_financeable_mcmv"
            label="Minha casa minha vida?"
          />
        )}
        <FieldMultiCharacteristics
          onChange={handleUpdateCount}
          typeId={currentType?.type_id}
        />
        <FieldMultiCondoCharacteristics onChange={handleUpdateCount} />
      </Col>
    </Row>
  );
}

export default MainFields;
