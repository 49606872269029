import React, { useEffect } from 'react';
import Tooltip from 'react-tooltip';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, formValueSelector, initialize, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
// Components
import Container from 'pages/Site/components/ContainerSite';
import Button from 'components/Button';
import FixedBar from 'components/FixedBar';
import { FieldBool, Select, Textarea } from 'components/Form';
import ListOptions from 'components/ListOptions';
import useFormValue from 'hooks/useFormValue';
import { withHighlightedButtons, withRequiredFields } from '../../helpers';
import { getNotificationResponse } from 'services/sites/propertyForms';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const FormSiteProperties = ({
  component,
  handleSubmit,
  submitting,
  pristine,
  change,

  form_is_email_featured,
  form_is_whatsapp_featured,
}) => {
  const form_is_realtor_shown = useFormValue('form_is_realtor_shown');

  useEffect(() => {
    Tooltip.rebuild();
  }, []);

  const type =
    form_is_email_featured && form_is_whatsapp_featured
      ? 'whatsapp_email_url'
      : form_is_email_featured
      ? 'email_url'
      : 'whatsapp_url';

  return (
    <form onSubmit={handleSubmit}>
      <img
        src={component[type]}
        alt=""
        className="h-flex__cell--shrink"
        style={{ margin: '0 auto 20px' }}
      />
      <Container>
        <ListOptions>
          <ListOptions.item
            title="Mostrar dados do corretor no formulário de contato?"
            text="Escolha se deverá ser mostrado dados do corretor responsável em cima do formulário."
            renderOptions={() => (
              <>
                <FieldBool name="form_is_realtor_shown" />
              </>
            )}
          />
          {form_is_realtor_shown && (
            <ListOptions.item
              title="Dados do corretor no site"
              text="Selecione quais dados do corretor devem aparecer no site."
              renderOptions={() => (
                <>
                  <FieldBool label="Foto?" name="realtor_is_photo_shown" />
                  <FieldBool label="Nome?" name="realtor_is_name_shown" />
                  <FieldBool
                    label={
                      <>
                        Telefone?{' '}
                      </>
                    }
                    name="realtor_is_phone_shown"
                    onChange={(_, value) => {
                      if (value) {
                        if (form_is_email_featured) {
                          change('highlighted_button', 3);
                        } else {
                          change('highlighted_button', 1);
                        }

                        change('form_is_whatsapp_featured', true);
                        return;
                      }

                      change('form_is_whatsapp_featured', false);
                      change('form_is_email_featured', true);
                      change('highlighted_button', 2);
                    }}
                  />
                  <FieldBool label="E-mail?" name="realtor_is_email_shown" />
                </>
              )}
            />
          )}
          <ListOptions.item
            title="Preenchimento obrigatório"
            text="Quais dados seu cliente precisa preencher obrigatóriamente."
            renderOptions={() => (
              <Field
                name="required_fields"
                buttonTemplate
                component={Select}
                canRemoveSelf={false}
                options={[
                  { value: 1, label: 'Telefone' },
                  { value: 2, label: 'E-mail' },
                  { value: 3, label: 'Telefone e E-mail' },
                ]}
                onChange={(_, value) => {
                  if (value === 1) {
                    change('form_is_required_email', false);
                    change('form_is_required_phone', true);
                  }

                  if (value === 2) {
                    change('form_is_required_email', true);
                    change('form_is_required_phone', false);
                  }

                  if (value === 3) {
                    change('form_is_required_email', true);
                    change('form_is_required_phone', true);
                  }
                }}
              />
            )}
          />
          <ListOptions.item
            title="Notificação para corretor sobre recebimento de novo contato"
            text="Escolha quais formas de aviso devem ser utilizadas"
            renderOptions={() => (
              <Field
                name="notification_response"
                buttonTemplate
                component={Select}
                canRemoveSelf={false}
                options={[
                  { value: 1, label: 'WhatsApp' },
                  { value: 2, label: 'E-mail' },
                  { value: 3, label: 'WhatsApp e E-mail' },
                ]}
              />
            )}
          />
          <ListOptions.item
            title="Botão de contato do formulário"
            text="Escolha qual botão deve estar em destaque no final do formulário."
            renderOptions={() => (
              <Field
                name="highlighted_button"
                buttonTemplate
                component={Select}
                canRemoveSelf={false}
                options={[
                  { value: 1, label: 'WhatsApp' },
                  { value: 2, label: 'E-mail' },
                  { value: 3, label: 'WhatsApp e E-mail' },
                ]}
                onChange={(_, value) => {
                  if (value === 1) {
                    change('form_is_email_featured', false);
                    change('form_is_whatsapp_featured', true);
                  }

                  if (value === 2) {
                    change('form_is_email_featured', true);
                    change('form_is_whatsapp_featured', false);
                  }

                  if (value === 3) {
                    change('form_is_email_featured', true);
                    change('form_is_whatsapp_featured', true);
                  }

                  if (value === 1 || value === 3)
                    change('realtor_is_phone_shown', true);
                }}
              />
            )}
          />
          <ListOptions.item
            title="Texto Padrão"
            text="Informe o texto que será apresentado como padrão no formulário de contato."
          >
            <div className="h-margin-top--15">
              <Field
                type="simple"
                name="form_contact_text"
                placeholder="Digite o texto"
                component={Textarea}
              />
            </div>
          </ListOptions.item>
        </ListOptions>
        <FixedBar style={{ left: 240 }}>
          <span className="h-flex__cell--grow" />
          <Button type="submit" color="success" disabled={submitting}>
            Salvar
          </Button>
        </FixedBar>
      </Container>
    </form>
  );
};

FormSiteProperties.defaultProps = defaultProps;
FormSiteProperties.propTypes = propTypes;

const selector = formValueSelector('FormSiteProperties');

export default compose(
  connect((state) => ({
    form_is_email_featured: selector(state, 'form_is_email_featured'),
    form_is_whatsapp_featured: selector(state, 'form_is_whatsapp_featured'),
  })),
  reduxForm({
    form: 'FormSiteProperties',
    onSubmitSuccess: (res, dispatch) => {
      let newData = {
        ...withHighlightedButtons(res.data),
        ...withRequiredFields(res.data),
      };

      newData = {
        ...newData,
        notification_response: getNotificationResponse(newData),
      };

      // Reinicializa o formulário com os valores
      dispatch(initialize('FormSiteProperties', newData, false));
    },
    keepDirtyOnReinitialize: true,
    enableReinitialize: true,
  })
)(FormSiteProperties);
