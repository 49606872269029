import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
// Components
import { Input, Select } from 'components/Form';
import ModalFooter from 'components/Modal/components/ModalFooter';
import Button from 'components/Button';
import GroupControl from 'components/GroupControl';
import GroupItem from 'components/GroupControl/components/GroupItem';
import validate from './validate';
// Shapes
import { DomainShape } from 'constants/shapes';

const propTypes = {
  domain: PropTypes.string.isRequired,
  domains: PropTypes.arrayOf(DomainShape),
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

const EmailBoxRedirectedForm = ({
  domains,
  change,
  handleSubmit,
  handleClose
}) => (
  <form onSubmit={handleSubmit}>
    <Row>
      <Field
        required
        label="Nome da caixa"
        name="name"
        component={Input}
        xs={12}
      />
    </Row>
    <Row>
      <Col xs={12}>
        <GroupControl required label="E-mail que você deseja criar">
          <GroupItem>
            <Field
              name="local_part"
              component={Input}
              autoComplete="new-email"
            />
          </GroupItem>
          <GroupItem size="small">
            <Field
              autoWidth
              name="domain_id"
              labelKey="email_url"
              valueKey="id"
              options={domains.data || []}
              placeholder=""
              loading={domains.isFetching}
              component={Select}
            />
          </GroupItem>
        </GroupControl>
      </Col>
    </Row>
    <Row>
      <Field
        label="Senha"
        name="password"
        component={Input}
        xs={6}
        required
        type="password"
        autoComplete="new-password"
      />
      <Field
        label="Confirmar Senha"
        name="password_confirm"
        component={Input}
        xs={6}
        required
        type="password"
        autoComplete="new-password"
      />
    </Row>
    <Row>
      <Field
        required
        label="Sua conta Gmail ou Hotmail:"
        xs={12}
        type="email"
        name="destiny_mail"
        component={Input}
        placeholder="Digite seu Gmail, Hotmail ou outros"
        autoComplete="new-email"
      />
    </Row>
    <ModalFooter>
      <Button color="white" colorText="secondary" onClick={handleClose}>
        Cancelar
      </Button>
      <span className="h-flex__cell--grow" />
      <Button type="submit" color="success">
        Salvar
      </Button>
    </ModalFooter>
  </form>
);

EmailBoxRedirectedForm.propTypes = propTypes;

export default reduxForm({
  form: 'EmailBoxRedirected',
  validate,
  enableReinitialize: true
})(EmailBoxRedirectedForm);
