import api, { apiStorage } from './index';
import _ from 'lodash';
import { responseMessage } from 'lib/service-helpers';
import Alert from 'react-s-alert';
import { SubmissionError } from 'redux-form';
import { STORAGE } from 'lib/HTTP';

export const getList = (params) =>
  api
    .getList('condos', params)
    .catch(responseMessage('Erro ao buscar os condomínios'));

export const getOne = (id, params) =>
  api.getOne('condos', id, {
    ...params,
    sort: 'calculated_price',
  });

export const getGrouped = (id, params) =>
  api.getOne(`condos/grouped`, id, {
    ...params
  });

export const getImages = (condoId, params) =>
  api.getList(`condos/${condoId}/images`, { ...params, sort: null });

export const addImage = ({ condo_id, ...params }) => {
  return apiStorage
    .uploadFile(`properties/condos/${condo_id}/images`, params)
    .catch(
      responseMessage({
        413: 'Tamanho máximo da imagem é 10 Mega',
      })
    );
};

export const removeImage = async (id, condoId) => {
  try {
    const response = await apiStorage.delete(
      `/properties/condos/${condoId}/images`,
      id
    );
    Alert.success('Imagem removida');
    return response;
  } catch (e) {
    Alert.success('Erro ao remover imagem');
    throw e;
  }
};

export const removeAllImages = async (condoId) => {
  try {
    const response = await STORAGE.delete(`/properties/condos/${condoId}/images`);

    return response;
  } catch (e) {
    throw e;
  }
};

export const alterImage = ({ id, condo_id, ...params }) =>
  api.update(`/condos/${condo_id}/images/${id}`, params);

export const transformPropertiesToTypes = (properties) => {
  const values = (properties || []).reduce((acc, property) => {
    // O tipo do imóvel
    const typeId = property?.type?.id;
    const typeName = property?.type?.title;

    // Os imóveis que já estão no array
    const properties = acc?.[typeId]?.properties || [];

    return {
      ...acc,
      [typeId]: {
        ...acc[typeId],
        typeId: typeId,
        title: typeName,
        properties: [...properties, property],
      },
    };
  }, {});

  return _.values(values);
};

/**
 * Retorna a lista de imóveis por tipo
 * @param propertyId
 * @param params
 * @returns {Promise<*>}
 */
export const getCondosByTypes = async (propertyId, params) => {
  try {
    const response = await getOne(propertyId, params);

    const types = response?.data?.properties.reduce((acc, property) => {
      // O tipo do imóvel
      const typeId = property?.situation_id;
      const typeName = 'Apartamento';

      // Os imóveis que já estão no array
      const properties = acc?.[typeId]?.properties || [];

      acc = {
        ...acc,
        [typeId]: {
          ...acc[typeId],
          typeId: typeId,
          title: typeName,
          properties: [...properties, property],
        },
      };

      return _.values(acc);
    }, {});

    return types;
  } catch (err) {
    throw err;
  }
};

export const create = (data) =>
  api
    .create('condos', data)
    .then(responseMessage('Condomínio cadastrado'))
    .catch(responseMessage('Erro ao cadastrar condomínio'));
export const update = (values) =>
  api
    .update('condos', values)
    .then(responseMessage('Condomínio alterado'))
    .catch(responseMessage('Erro ao alterar o condomínio'));

export const remove = (id) =>
  api
    .delete('condos', id)
    .then(responseMessage('Condomínio excluído'))
    .catch(responseMessage('Erro ao deletar condomínio'));

export const getCondosCharacteristics = (params) => {
  return api.getList('condos/characteristics', {
    ...params,
    sort: params ? params.sort : 'by_title',
  });
};
export const createCondosCharacteristics = (data) =>
  api.create('condos/characteristics', data);
export const removeCondosCharacteristics = (id) =>
  api.delete('condos/characteristics', id);

export const transferCondo = (from, to, values) =>
  api.create(`condos/${from}/transfers/${to}`, values);

export const transferAndDelete = async (from, to) => {
  try {
    await transferCondo(from, to);
    return await remove(from);
  } catch {
    Alert.success('Erro ao excluir');
    return SubmissionError({
      _error: 'Erro ao excluir esse condomínio',
    });
  }
};

export const updateImageArea = (id, data) =>
  apiStorage.patch(`/properties/condos/${id}/image-area`, data);
