import PropTypes from 'prop-types';
import React from 'react';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
// Components
import FormGroup from 'components/Form/components/FormGroup';
// Constants
import { CONTROL_SHAPE } from 'constants/shapes';
import { customPlaceholder } from 'components/Form/components/InputPhone/helpers';

class PhoneInput extends React.Component {
  static defaultProps = {
    label: null,
    required: false,
    maxLength: null,
    type: 'text',
    className: null,
    formGroupClassName: null,
    placeholder: 'Digite...',
    autoFocus: false
  };

  static propTypes = {
    ...CONTROL_SHAPE,
    maxLength: PropTypes.number,
    type: PropTypes.string,
    autoFocus: PropTypes.bool
  };

  state = {
    maxLength: null
  };

  get maxLength() {
    const { maxLength } = this.props;

    try {
      return maxLength || this.state.maxLength;
    } catch {
      return null;
    }
  }

  clearInput = () => {
    const { input } = this.props;
    input.onChange('');
  };

  updateMaxLength = iso2 => {
    try {
      const exampleNumber = window.intlTelInputUtils.getExampleNumber(
        iso2,
        window.intlTelInputUtils.numberFormat.INTERNATIONAL,
        1
      );

      this.setState({
        maxLength: exampleNumber.length
      });
    } catch {
      return null;
    }
  };

  render() {
    const {
      defaultCountry,
      refName,
      required,
      maxLength,
      autoFocus,
      tabIndex,
      className,
      formGroupClassName,
      type,
      valueFormat,
      label,
      input,
      placeholder,
      disabled,
      meta,
      prefix,
      labelRender,
      onKeyDown,
      onKeyUp,
      onKeyPress,
      onPhoneNumberChange,
      onSelectFlag,
      onChange,
      ...anotherProps
    } = this.props;

    return (
      <FormGroup
        {...anotherProps}
        className={formGroupClassName}
        maxLength={maxLength}
        labelText={label}
        isRequired={required}
        isDisabled={disabled}
        input={input}
        meta={meta}
        labelRender={labelRender}
      >
        {!this.props.defaultCountry ? (
          <div className="FormControl" />
        ) : (
          <IntlTelInput
            telInputProps={{
              maxLength: this.maxLength
            }}
            customPlaceholder={customPlaceholder}
            defaultCountry={this.props.defaultCountry}
            format
            ref={refName}
            id={input.name}
            inputClassName="FormControl"
            preferredCountries={['br', 'us', 'ca', 'ru', 'cn', 'pt']}
            onSelectFlag={(value, item, placeholder) => {
              this.clearInput();
              this.updateMaxLength(item.iso2);
              if (onSelectFlag) onSelectFlag(value, item, placeholder);
            }}
            onPhoneNumberChange={(status, value, countryData) => {
              this.updateMaxLength(countryData.iso2);

              if (onPhoneNumberChange)
                onPhoneNumberChange(status, value, countryData);

              input.onChange(value);
            }}
            value={input.value}
          />
        )}
      </FormGroup>
    );
  }
}

export default PhoneInput;
