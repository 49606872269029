import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  position: relative;

  .Modal__footer {
    margin-top: -15px !important;
  }
`;

export const BoxLeft = styled.div`
  flex-shrink: 0;
  border-right: 1px solid #dedede;
  padding: 20px 20px 30px 0;
  margin-top: -20px;
  margin-bottom: -20px;

  width: 50%;
`;

export const BoxRight = styled.div`
  width: calc(50% + 20px);
  overflow: auto;
  margin-right: -20px;
  margin-top: -20px;
  margin-bottom: -20px;
  display: flex;
  flex-direction: column;

  .FormGroup {
    margin-bottom: 0;
  }
`;

export const Form = styled.form`
  .Modal__footer {
    border-top: 1px solid #dedede;
  }
`;

export const Box = styled.div`
  padding: 20px;
  flex-shrink: 0;
  & + & {
    border-top: 1px solid #dedede;
  }
`;

export const SeeFullForm = styled.button`
  display: block;
  padding: 10px 15px;
  color: ${p => p.theme.colors.secondary};
  text-decoration: underline;
  margin: 0 auto 15px;
  cursor: pointer;

  :hover {
    color: #000;
    background: rgba(0, 0, 0, 0.1);
  }
`;
