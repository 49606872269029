import React, { useState } from 'react';
import SelectLink from 'components/SelectLink';
import useReduxForm from 'hooks/useReduxForm';
import { useDispatch } from 'react-redux';

export function TaxesRent(props) {
  const dispatch = useDispatch();
  const { change } = useReduxForm();

  const [selectedOption, setSelectedOption] = useState(true);

  const handleChange = (option) => {
      setSelectedOption(option.value);
        dispatch(change('price.with_rental_taxes', option.value));
        props.onChange();
    };

  const options = [
    { label: 'Valor total', value: true },
    { label: 'Apenas imóvel', value: false },
  ];

  return (
    <div style={{ marginBottom: '-4px', width: '100%' }}>
      <div style={{ textAlign: 'right' }}>
        <SelectLink
          value={selectedOption}
          options={options}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}
