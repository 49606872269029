import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// Modules
import {
  openModalAddEmailAccount,
  openModalEmailBoxRedirected,
  openModalEmailPassword,
} from 'modules/modal';
import { accountsSelector, fetchAccounts, removeAccount } from './module';
import {
  fetchAccounts as fetchLocalAccounts,
  localAccountsSelector,
} from '../LocalAccounts/module';
// Components
import Container from 'components/Container';
import Button from 'components/Button';
import MainTitle from 'components/MainTitle';
import TableAccounts from './components/TableAccounts';
import * as emailService from 'services/settings/email';

class Accounts extends React.Component {
  static propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
    isFetching: PropTypes.bool,

    fetchAccounts: PropTypes.func,
    openModalEmailBoxRedirected: PropTypes.func,
  };

  static defaultProps = {
    data: [],
    isFetching: null,

    fetchAccounts: null,
    openModalEmailBoxRedirected: null,
  };

  componentDidMount() {
    this.props.fetchAccounts();
  }

  openModal = (username) => {
    this.props.openModalEmailBoxRedirected({
      username,
      onSubmitSuccess: () => {
        this.props.fetchAccounts();
      },
    });
  };

  openModalLocalAccounts = (username) => {
    this.props.openModalAddEmailAccount({
      username,
      onSubmitSuccess: () => {
        this.props.fetchLocalAccounts();
      },
    });
  };

  handleUpdate =
    (address) =>
    ({ goto }) => {
      return emailService.updateGoTo(address, goto);
    };

  handleUpdateSuccess = () => {
    this.props.fetchAccounts();
  };

  handleUpdatePassword = (username) => () => {
    const { openModalEmailPassword } = this.props;
    openModalEmailPassword(username);
  };

  get hasLocalAccounts() {
    const { localAccounts } = this.props;
    return !!localAccounts.length;
  }

  render() {
    const { data } = this.props;

    if (data.length === 0) return null;

    return (
      <>
        <MainTitle
          title="Caixas redirecionadas"
          text="Integre seu e-mail personalizado com o Gmail ou Hotmail."
        >
          <Button
            tagName="a"
            color="primary"
            href="https://tecimob.com.br/ajuda/category/email/"
            target="_blank"
          >
            Ajuda
          </Button>
          {!this.hasLocalAccounts && (
            <Button
              color="white"
              colorText="secondary"
              onClick={this.openModalLocalAccounts}
            >
              Criar conta local
            </Button>
          )}
          <Button color="secondary" onClick={this.openModal}>
            Criar caixa redirecionada
          </Button>
        </MainTitle>
        <Container padding>
          <TableAccounts
            data={data}
            handleUpdate={this.handleUpdate}
            handleUpdateSuccess={this.handleUpdateSuccess}
            handleUpdatePassword={this.handleUpdatePassword}
            handleRemove={this.props.removeAccount}
          />
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  data: accountsSelector.getAll(state),
  localAccounts: localAccountsSelector.getAll(state),
  isFetching: accountsSelector.isFetching(state),
});

const mapDispatchToProps = {
  fetchAccounts,
  removeAccount,
  openModalEmailBoxRedirected,
  openModalAddEmailAccount,
  openModalEmailPassword,
  fetchLocalAccounts,
};

export default connect(mapStateToProps, mapDispatchToProps)(Accounts);
