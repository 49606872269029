import { FaWhatsapp } from 'react-icons/fa';

export const APP_NAME = 'tc';
export const API_BASE_URL = window.location.origin + '/data/';

export const API_STORAGE_HOST = process.env.REACT_APP_API_STORAGE;
export const API_STORAGE_URL = `${API_STORAGE_HOST}/api/`;
export const API_PDF = process.env.REACT_APP_API_PDF;
// export const API_PDF = 'http://localhost:7000';

export const API_HOST = process.env.REACT_APP_API_URL;
export const API_URL = `${API_HOST}/api/`;
// export const API_URL = `https://api.gerenciarimoveis-cf.com.br/api/`;
// export const API_URL = `https://api.tecimob-dev.com.br/api/`;
export const MAPS_KEY = process.env.REACT_APP_MAPS_KEY;
export const ORULO_CLIENT_ID = 'Nwv_E5hRc8YbSHJKBgQqJ_Xm90qbWLOnzjjWZ96OK-Y';
export const GOOGLE_CLIENT_ID =
  '535630744580-vpikmglep27dft9aa9f7dvgmsqrb5cco.apps.googleusercontent.com';
export const GOOGLE_CLIENT_SECRET = 'A9veLUoZVPLkJdwjJ0rLk-Ku';
export const RECAPTCHA_SECRET = '6LcerJsUAAAAAKw6v70xur2BM2Y5k3tG74wSoSk8';
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
export const TECIMOB_RESALE_ID = '3390c2f2-cc7a-4f15-8213-05fed687eee7';

export const REDIRECT_URI = window.location.origin;

export const IMAGE_SIZES = {
  small: '/200x200',
  medium: '/600x450',
  large: '/1200x900',
  opengraph: '/200x200',
  original: '',
  facebook: '/200x200',
  linkedin: '/200x200',
  twitter: '/200x200',
  instagram: '/200x200',
};

//
export const ACCEPT_PHOTOS = 'image/jpeg, image/jpg, image/png';
export const ACCEPT_DOCUMENTS =
  'application/rtf, application/x-rtf, text/richtext, application/msword, application/excel, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.wordprocessingml.template, application/vnd.ms-word.document.macroEnabled.12, application/vnd.ms-word.template.macroEnabled.12, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.spreadsheetml.template, text/xml, application/xml';
export const ACCEPT_ALL_FILES = `${ACCEPT_PHOTOS},${ACCEPT_DOCUMENTS}`;

/**
 * Cores principais do projeto
 */
export const COLORS_PRIMARY = [
  'primary',
  'secondary',
  'success',
  'danger',
  'caution',
];

/**
 * Cores secundarias do projeto
 */
export const COLORS_SECONDARY = ['white', 'white-light', 'dark', 'dark-light'];

/**
 * Cores das redes sociais
 */
export const SOCIAL_COLORS = ['facebook', 'twitter', 'google-plus'];

/**
 * Cores em hexadecimal
 */
export const COLORS_HEXADECIMAL = [
  '#0088FE',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  '#A9FFFD',
  '#DC80FF',
  '#FF6749',
  '#295734',
];
export const COLOR = '#295734';

/**
 * Todas as cores
 * @type {[*]}
 */
export const COLORS = [
  ...COLORS_PRIMARY,
  ...COLORS_SECONDARY,
  ...SOCIAL_COLORS,
  'primary-light',
  'primary-dark',
  'secondary-light',
  'secondary-dark',
  'success-light',
  'success-dark',
  'danger-light',
  'danger-dark',
  'caution-light',
  'caution-dark',
];

/**
 * Tamanhos padrão
 */
export const SIZES = ['small', 'medium', 'large'];

/**
 * Todos os nomes de animação
 */
export const ANIMATION_NAMES = [
  'bounce',
  'flash',
  'pulse',
  'rubberBand',
  'shake',
  'swing',
  'tada',
  'wobble',
  'jello',
  'bounceIn',
  'bounceInDown',
  'bounceInLeft',
  'bounceInRight',
  'bounceInUp',
  'bounceOut',
  'bounceOutDown',
  'bounceOutLeft',
  'bounceOutRight',
  'bounceOutUp',
  'fadeIn',
  'fadeInDown',
  'fadeInDownBig',
  'fadeInLeft',
  'fadeInLeftBig',
  'fadeInRight',
  'fadeInRightBig',
  'fadeInUp',
  'fadeInUpBig',
  'fadeOut',
  'fadeOutDown',
  'fadeOutDownBig',
  'fadeOutLeft',
  'fadeOutLeftBig',
  'fadeOutRight',
  'fadeOutRightBig',
  'fadeOutUp',
  'fadeOutUpBig',
  'flip',
  'flipInX',
  'flipInY',
  'flipOutX',
  'flipOutY',
  'lightSpeedIn',
  'lightSpeedOut',
  'rotateIn',
  'rotateInDownLeft',
  'rotateInDownRight',
  'rotateInUpLeft',
  'rotateInUpRight',
  'rotateOut',
  'rotateOutDownLeft',
  'rotateOutDownRight',
  'rotateOutUpLeft',
  'rotateOutUpRight',
  'slideInUp',
  'slideInDown',
  'slideInLeft',
  'slideInRight',
  'slideOutUp',
  'slideOutDown',
  'slideOutLeft',
  'slideOutRight',
  'zoomIn',
  'zoomInDown',
  'zoomInLeft',
  'zoomInRight',
  'zoomInUp',
  'zoomOut',
  'zoomOutDown',
  'zoomOutLeft',
  'zoomOutRight',
  'zoomOutUp',
  'hinge',
  'jackInTheBox',
  'rollIn',
  'rollOut',
];

/**
 * Todos os icones permitidos
 */
export const ICON_NAMES = [
  'case',
  'check',
  'delete',
  'file-plus',
  'favorite',
  'mail-send',
  'refresh-alt',
  'file-text',
  'account',
  'account-add',
  'accounts-alt',
  'arrow-select',
  'calendar-note',
  'car',
  'card-travel',
  'check-circle',
  'check-square',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'circle-o',
  'close',
  'close-circle',
  'crop-free',
  'download',
  'edit',
  'email',
  'facebook',
  'globe',
  'google-plus',
  'home',
  'hotel',
  'info',
  'lock',
  'money',
  'money-box',
  'notifications',
  'phone',
  'plus',
  'print',
  'receipt',
  'refresh',
  'rotate-left',
  'search',
  'search--white',
  'sort-amount-desc',
  'spinner',
  'square-o',
  'star',
  'star-outline',
  'trending-up',
  'twitter',
  'view-dashboard',
  'logo-tecimob',
  'linkedin',
  'whatsapp',
  'plus-circle',
];

export const AUDIT_ICONS = {
  created: { icon: 'plus', color: 'primary' },
  updated: { icon: 'refresh-alt', color: 'success' },
  deleted: { icon: 'delete', color: 'danger' },
  restored: { icon: 'spinner', color: 'caution' },

  // Clientes
  property_forward_email: { icon: 'mail-send', color: 'secondary' },
  matched_forward_email: { icon: 'favorite', color: 'danger' },
  note_created: { icon: 'file-text', color: 'primary' },
  note_updated: { icon: 'refresh-alt', color: 'success' },
  note_deleted: { icon: 'delete', color: 'danger' },

  // Documentos
  document_created: { icon: 'file-plus', color: 'primary' },
  document_deleted: { icon: 'delete', color: 'danger' },

  // Perfil de bus icon: '', color: 'secondary'ca
  search_profile_created: { icon: 'search', color: 'primary' },
  search_profile_updated: { icon: 'search', color: 'secondary' },
  search_profile_deleted: { icon: 'delete', color: 'danger' },

  // Eventos
  calendar_created: { icon: 'calendar-note', color: 'primary' },
  calendar_updated: { icon: 'refresh-alt', color: 'success' },
  calendar_deleted: { icon: 'delete', color: 'danger' },
  calendar_restored: { icon: 'spinner', color: 'caution' },
  calendar_closed: { icon: 'check', color: 'success' },

  // CRM
  deal_created: { icon: 'case', color: 'primary' },
  deal_stage_add: { icon: 'plus', color: 'primary' },
  deal_stage_updated: { icon: 'arrow-right', color: 'success' },
  deal_stage_removed: { icon: 'delete', color: 'danger' },
  earned_deal: { icon: 'money', color: 'success' },
  lost_deal: { icon: 'close', color: 'danger' },

  // Matched profiles
  matched_created: { icon: 'favorite', color: 'primary' },
  matched_discarded: { icon: 'close', color: 'danger' },
  matched_interested: { icon: 'favorite', color: 'caution' },
  matched_forward_whatsapp: { icon: FaWhatsapp, color: 'success' },

  // Leads
  lead_received: { icon: 'account-add', color: 'success' },

  // Imóveis
  property_created: { icon: 'plus', color: 'primary' },
  property_updated: { icon: 'refresh-alt', color: 'success' },
  property_deleted: { icon: 'delete', color: 'danger' },
  property_restored: { icon: 'spinner', color: 'caution' },

  // Indices Financeiros
  financial_value_updated: { icon: 'money-box', color: 'success' },

  // Atualização
  updated_detail: { icon: 'refresh-alt', color: 'success' },
};
