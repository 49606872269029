// import styles from './styles'

import { Field } from 'redux-form';
import { Input } from 'components/Form';
import React from 'react';

export function Reference({
  name,
  label = 'Referência do novo imóvel',
  ...props
}) {
  return (
    <Field
      name={name}
      label="Referência do novo imóvel"
      maxLength={19}
      component={Input}
      {...props}
    />
  );
}
