import Modal from 'react-modal';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import { Field, reduxForm } from 'redux-form';
import Button from 'components/Button';
import { Row } from 'react-flexbox-grid';
import { Input } from 'components/Form';
import * as featuredListsServices from 'services/sites/featuredLists';
import { useRequest } from 'hooks/useRequest';
import { useEffect } from 'react';

function ModalFeaturedGallery({
  modalConfig,
  isOpen,
  handleClose,
  modalType,
  id,
  handleSubmit,
  initialize,
}) {
  const { data, fetchData } = useRequest({
    request: () => featuredListsServices.getFeaturedList(id),
    initialFetch: false,
  });

  const isEditting = !!id;

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      initialize(data);
    }
  }, [id, data]);

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        title={isEditting ? 'Editar galeria' : 'Adicionar galeria'}
      >
        <form onSubmit={handleSubmit}>
          <Row>
            <Field
              xs={12}
              label="Digite o título desejado"
              name="title"
              component={Input}
            />
          </Row>
          <ModalFooter>
            <Button type="button" onClick={handleClose} color="white">
              Cancelar
            </Button>
            <span className="h-flex__cell--grow" />
            <Button type="submit" color="success">
              {isEditting ? 'Editar galeria' : 'Adicionar galeria'}
            </Button>
          </ModalFooter>
        </form>
      </ModalTemplate>
    </Modal>
  );
}

export default reduxForm({
  form: 'ModalFeaturedGallery',
  onSubmit: (values) => {
    return featuredListsServices.upsertFeaturedList(values);
  },
  onSubmitSuccess: (res, dispatch, props) => {
    if (props.onSuccess) props.onSuccess(res, dispatch, props);

    // Fecha a modal
    props.handleClose();
  },
})(ModalFeaturedGallery);
