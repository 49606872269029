import * as S from './styles';
import { MdHome } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { selectedsSelector } from 'modules/selecteds';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export default function SelectedProperties() {
  const propertiesSelected = useSelector(
    selectedsSelector('properties_search')
  );

  const history = useHistory();

  useEffect(() => {
    localStorage.setItem('@tecimob/properties_search', propertiesSelected);
  }, [propertiesSelected]);

  if (!propertiesSelected?.length || propertiesSelected?.length === 0) {
    return null;
  }

  const handleWrapperClick = () => {
    const queryParams = propertiesSelected
      .map((id) => `filter[by_id][]=${id}`)
      .join('&');
    history.push(`/properties/search?${queryParams}&limit=50&offset=1`);
  };

  return (
    <S.Wrapper onClick={handleWrapperClick}>
      <S.Ball>
        <S.Number>
          {propertiesSelected.length.toString().padStart(2, '0')}
        </S.Number>
        <MdHome />
      </S.Ball>
    </S.Wrapper>
  );
}
