import styled from 'styled-components';
import { rgba } from 'polished';
import Image from 'components/Image';
import TextOverflow from 'components/TextOverflow';
import { MdDelete } from 'react-icons/md';
import CustomCheckbox from './components/Checkbox';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid ${rgba('#8497a6', 0.29)};

  & + & {
    margin-top: 20px;
  }
`;

export const Check = styled(CustomCheckbox)`
  position: absolute;
  top: -15px;
  left: -15px;
  z-index: 10;
`;

export const BgSelected = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 5;

  border: 1px solid #0084f4;
  background: rgba(229, 239, 249, 0.5);
  border-radius: 4px;
`;

export const Row = styled.div`
  display: flex;
`;

export const Spacer = styled.div`
  flex-grow: 1;
`;

export const RowTop = styled(Row)``;

export const RowBottom = styled(Row)`
  margin-top: 20px;
  justify-content: space-between;
`;

export const WrapImage = styled.div`
  position: relative;
  width: 145px;
  height: 145px;
`;

export const LabelImage = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px;
  color: #fff;
  text-align: center;
  background: ${(p) => p.theme.colors.danger};
  border-radius: 0 0 4px 4px;
`;

export const PropertyImage = styled(Image)`
  width: inherit;
  height: inherit;
  object-fit: cover;
  border-radius: 4px;
  min-width: 0;
  flex-shrink: 0;
`;

export const WrapText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Text = styled(TextOverflow)`
  font-size: 14px;
  margin: 2px 0;
  max-width: 100%;
`;

export const TextWrapper = styled.div`
  flex: 1 0;
  min-width: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.h1`
  width: 100%;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  color: ${(p) => p.theme.colors.primary};

  .Label {
    margin-left: 5px;
  }
`;

export const Price = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin: 12px 0 8px;
  color: ${(p) => (p.theme.isSell ? '#01AFAD' : '#FF9702')};
`;

export const Checkbox = styled(Check)`
  padding: 5px;
  margin: -5px;
  cursor: pointer;
  font-size: 40px;
  color: ${(p) =>
    p.theme.isChecked ? p.theme.colors.success : p.theme.colors.primary};
`;

export const Delete = styled(MdDelete)`
  padding: 5px;
  margin: -5px;
  cursor: pointer;
  font-size: 35px;
  color: ${(p) => p.theme.colors.danger};
  border-radius: 50%;

  :hover {
    background: #f1f1f1;
  }
`;

export const Content = styled.div`
  display: flex;
  margin-left: 20px;
  flex: 1 0;
  min-width: 0;
`;

export const Actions = styled.div`
  flex-shrink: 0;
  min-width: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;

  .Button {
    margin-bottom: 10px;
  }
`;
