import React from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
// Components
import { FieldBool } from 'components/Form';
import { ModalFooter } from 'components/Modal';
import Button from 'components/Button';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

function FormOruloDisconnect({ handleSubmit, handleClose }) {
  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col xs={7}>
          <FieldBool
            label="Excluir imóveis?"
            name="should_destroy_properties"
          />
        </Col>
      </Row>
      <ModalFooter>
        <Button color="white" colorText="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="danger">
          Desconectar
        </Button>
      </ModalFooter>
    </form>
  );
}

FormOruloDisconnect.defaultProps = defaultProps;
FormOruloDisconnect.propTypes = propTypes;

export default reduxForm({
  form: 'FormOruloDisconnect',
  enableReinitialize: true
})(FormOruloDisconnect);
