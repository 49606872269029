import { scrollToElement } from 'lib/animation-helpers';

/**
 * Funcao que pega o numero total de caracteres que podem ser digitados
 * @param input { Object } - Objeto input retornado pelo redux form representa o input em sí
 * @param maxLength { Number } - Número maximo de caracteres
 * @returns {number} o total de caracteres
 */
export const getTotal = (input, maxLength) => {
  return input.value ? maxLength - input.value.toString().length : maxLength;
};

export const minMax = (min, max) => (value) => {
  if (value < min) return min;
  if (value > max) return max;
  return value;
};

export const maxLength = (max) => (value, prevValue) => {
  return value.length <= max ? value : prevValue;
};

export const positiveNumbers = (value) => {
  if (!value) return null;

  if (value && value >= 0) {
    return value;
  }
  return null;
};

export const onlyDecimal = (value) => {
  value = value.replace(/[^0-9.]/g, ''); // Remove all chars except numbers and .

  // Create an array with sections split by .
  const sections = value.split('.');

  // Remove any leading 0s apart from single 0
  if (sections[0] !== '0' && sections[0] !== '00') {
    sections[0] = sections[0].replace(/^0+/, '');
  } else {
    sections[0] = '0';
  }

  // If numbers exist after first .
  if (sections[1]) {
    // Join first two sections and truncate end section to length 2
    return sections[0] + '.' + sections[1].slice(0, 2);
    // If original value had a decimal place at the end, add it back
  } else if (value.indexOf('.') !== -1) {
    return sections[0] + '.';
    // Otherwise, return only section
  } else {
    return sections[0];
  }
};

/**
 * Fação que verifica se o campo está sujo ou não está
 * @param input { Object } - Objeto input retornado pelo redux form representa o input em sí
 * @returns {boolean} true para está sujo
 */
export const isInputDirty = (input) =>
  !(input.value === '' || input.value === undefined);

export const normalizeNumbers = (value) => value && value.replace(/[^\d]/g, '');
export const normalizeLowercase = (value) => value.toLowerCase();
export const normalizeUppercase = (value) => value.toUpperCase();

/**
 * Função normalize mascara CEP
 * @param value {String} - Cep sem formatação
 * @returns {String} - Cep formatado 99999-999
 */
export const normalizeCep = (value) => {
  if (!value) {
    return value;
  }

  // pega só os numeros da
  const onlyNums = normalizeNumbers(value);

  if (onlyNums.length <= 5) {
    return onlyNums;
  }

  return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 8)}`;
};

/**
 * Função normalize mascara CEP
 * @param value {String} - Cep sem formatação
 * @returns {String} - Cep formatado 99999-999
 */
export const normalizeCreditCard = (value) => {
  if (!value) {
    return value;
  }

  // pega só os numeros da
  const onlyNums = normalizeNumbers(value);

  if (onlyNums.length > 12) {
    return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4, 8)} ${onlyNums.slice(
      8,
      12
    )} ${onlyNums.slice(12, 16)}`;
  }

  if (onlyNums.length > 8) {
    return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4, 8)} ${onlyNums.slice(
      8,
      12
    )}`;
  }

  if (onlyNums.length > 4) {
    return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4, 8)}`;
  }

  return onlyNums;
};

/**
 * Funcao que normaliza o CPF
 * @param value
 * @return {*}
 */
export const normalizeCpf = (value) => {
  if (!value) {
    return value;
  }

  const onlyNums = normalizeNumbers(value);
  const length = onlyNums.length;

  if (length <= 3) {
    return onlyNums;
  } else if (length <= 6) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}`;
  } else if (length <= 9) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(
      6,
      9
    )}`;
  }

  return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(
    6,
    9
  )}-${onlyNums.slice(9, 11)}`;
};

/**
 * Função que normaliza o cnpj
 * @param value
 * @return {*}
 */
export const normalizeCnpj = (value) => {
  if (!value) {
    return value;
  }

  const onlyNums = normalizeNumbers(value);
  const length = onlyNums.length;

  if (length <= 2) {
    return onlyNums;
  } else if (length <= 5) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}`;
  } else if (length <= 8) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
      5,
      8
    )}`;
  } else if (length <= 12) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
      5,
      8
    )}/${onlyNums.slice(8, 12)}`;
  }

  return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
    5,
    8
  )}/${onlyNums.slice(8, 12)}-${onlyNums.slice(12, 14)}`;
};

/**
 * Normalizando a data
 * @param value
 * @param prevValue
 * @return {*}
 */
export const normalizeDate = (value) => {
  if (!value) return value;

  const onlyNums = normalizeNumbers(value);

  const day = onlyNums.slice(0, 2) >= 32 ? 31 : onlyNums.slice(0, 2);
  const month = onlyNums.slice(2, 4) >= 13 ? 12 : onlyNums.slice(2, 4);
  const year = onlyNums.slice(4, 8);

  if (onlyNums.length <= 2) {
    return day;
  } else if (onlyNums.length <= 4) {
    return `${day}/${month}`;
  }

  return `${day}/${month}/${year}`;
};

/**
 * Normalizando o mês e ano
 * @param value
 * @return {*}
 */
export const normalizeMonthYear = (value) => {
  if (!value) return value;

  const onlyNums = normalizeNumbers(value);

  const month = onlyNums.slice(0, 2) >= 13 ? 12 : onlyNums.slice(0, 2);
  const year = onlyNums.slice(2, 4);

  if (onlyNums.length <= 2) {
    return month;
  }

  return `${month}/${year}`;
};

export const normalizeMonthYearFull = (value) => {
  if (!value) return value;

  const onlyNums = normalizeNumbers(value);

  const month = onlyNums.slice(0, 2) >= 13 ? 12 : onlyNums.slice(0, 2);
  const year = onlyNums.slice(2, 6);

  if (onlyNums.length <= 2) {
    return month;
  }

  return `${month}/${year}`;
};

/**
 * Normalizando o mês e ano
 * @param value
 * @return {*}
 */
export const normalizeMonth = (value) => {
  if (!value) return value;

  const onlyNums = normalizeNumbers(value);

  return onlyNums.slice(0, 2) >= 13 ? 12 : onlyNums.slice(0, 2);
};

/**
 * Normalizar o telefone
 * @param value
 * @return {*}
 */
export const normalizePhone = (value) => {
  if (!value) {
    return value;
  }

  const onlyNums = normalizeNumbers(value);
  const length = onlyNums.length;

  if (length < 3) {
    return onlyNums;
  }

  if (length <= 6) {
    return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 6)}`;
  }

  if (length <= 10) {
    return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 6)}-${onlyNums.slice(
      6,
      10
    )}`;
  }

  return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 3)} ${onlyNums.slice(
    3,
    7
  )}-${onlyNums.slice(7, 11)}`;
};

/**
 * Simplifica o valor do select
 * @param value
 * @param valueKey
 * @return {*}
 */
export const parseValueSelect = (value, valueKey = 'id') => {
  if (value) {
    return Array.isArray(value)
      ? value.map((arr) => arr[valueKey])
      : value[valueKey];
  }

  return value;
};

/**
 * Simplifica o valor do select
 * @param value
 * @param valueKey
 * @return {*}
 */
export const parseSelect =
  (valueKey = 'id') =>
  (value) => {
    if (value) {
      return Array.isArray(value)
        ? value.map((arr) => arr[valueKey])
        : value[valueKey];
    }
    return value;
  };

export const parseValueBool = (value) => {
  return value === 'true' || value === true;
};

export const parseCheckbox = (value) => !!value;

// transforma um objeto em um formData
export const getFormData = (params) => {
  const fd = new FormData();

  Object.keys(params).forEach((param) => {
    fd.append(param, params[param]);
  });

  return fd;
};

/**
 * Retorna o indice do erro que existe no formulário
 * retorna -1 caso não encontre nenhum
 * @param errors
 * @returns {number}
 */
export const getFieldError = (errors) => {
  const keyErrors = Object.keys(errors);

  for (let index = 0; index <= keyErrors.length; index++) {
    if (document.querySelector('.has-error')) {
      return index;
    }
  }
  return -1;
};

/**
 * Função que faz ele "scrollar" até o primeiro field com erro
 * @param container - Query Selector do elemento pai com overflow
 * @returns {Function}
 */
export const scrollToFirstError =
  (container = '.Wrapper__inner') =>
  (errors) => {
    if (!errors) return false;

    // Pega o indice do erro que existe no formulário
    let indexError = getFieldError(errors);

    // Verifica se existe um erro na lista
    if (indexError < 0) return false;

    let marginTop = 25;
    let querySelector = `.has-error`;
    let $element = document.querySelector(querySelector);

    if ($element && $element.tagName !== 'LABEL') {
      marginTop += 25;
    }

    scrollToElement(`.has-error`, container, {
      marginTop,
      duration: 500,
    });
  };
