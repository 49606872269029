import Button from 'components/Button';
import FixedBar from 'components/FixedBar';
import React from 'react';
import { Field, initialize, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
// Components
import Container from 'pages/Site/components/ContainerSite';
import {
  CarouselSelect,
  FieldBool,
  OrderOptions,
  Select,
} from 'components/Form';
import ListOptions from 'components/ListOptions';
// Lib
import { BOOL } from 'constants/options';
import useFormValue from 'hooks/useFormValue';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const FormSiteProperties = ({
  components,
  handleSubmit,
  submitting,
  pristine,
}) => {
  const featuredes_is_per_transaction = useFormValue(
    'featuredes_is_per_transaction'
  );

  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="card_type"
        options={components}
        component={CarouselSelect}
        className="h-margin-bottom--15"
        valueKey="type"
      />
      <Container>
        <ListOptions>
          <ListOptions.item
            title="Ordenação dos imóveis"
            text="Qual deve ser a ordem de apresentação dos imóveis após uma pesquisa?"
            renderOptions={() => (
              <Field
                name="homepage_order_type"
                component={Select}
                options={[
                  { label: 'Últimos Cadastrados', value: '1' },
                  { label: 'Últimos imóveis atualizados', value: '4' },
                  { label: 'Menor para o maior preço', value: '2' },
                  { label: 'Maior para o menor preço', value: '3' },
                ]}
                className="h-margin-left--15"
              />
            )}
          />
          <ListOptions.item
            title="Mostrar centavos do preço dos imóveis?"
            text="Defina se os centavos dos preços dos imóveis devem aparecer em seu site."
            renderOptions={() => <FieldBool name="is_cents_shown" />}
          />
          <ListOptions.item
            title="Últimos Imóveis visitados"
            text="Mostrar na página inicial os últimos imóveis visitados pelo cliente."
            renderOptions={() => (
              <Field
                buttonTemplate
                canRemoveSelf={false}
                name="is_last_visiteds_shown"
                component={Select}
                options={BOOL}
              />
            )}
          />
          <ListOptions.item
            title="Agrupar imóveis"
            text="Agrupar os imóveis que são do mesmo condomínio no seu site?"
            renderOptions={() => <FieldBool name="is_grouping_condos" />}
          />
          <ListOptions.item
            title="Separar imóveis por tipo de transação?"
            text="Defina se os imóveis serão agrupados por transação na página inicial."
            renderOptions={() => (
              <>
                {featuredes_is_per_transaction && (
                  <div style={{ marginTop: '-9px', marginRight: '10px' }}>
                    <Field
                      label="Ordem (arraste para ordenar)"
                      name="featuredes_per_transaction_order"
                      component={OrderOptions}
                    />
                  </div>
                )}
                <FieldBool name="featuredes_is_per_transaction" />
              </>
            )}
          />
          <ListOptions.item
            title="Abrir imóvel na mesma página?"
            renderOptions={() => (
              <Field
                buttonTemplate
                canRemoveSelf={false}
                name="open_link_same_tab"
                component={Select}
                options={BOOL}
              />
            )}
          />
          <ListOptions.item
            title="Mostrar área de favoritos?"
            renderOptions={() => (
              <Field
                buttonTemplate
                canRemoveSelf={false}
                name="is_favorites_shown"
                component={Select}
                options={BOOL}
              />
            )}
          />
        </ListOptions>
        <FixedBar style={{ left: 240 }}>
          <span className="h-flex__cell--grow" />
          <Button type="submit" color="success" disabled={submitting}>
            Salvar
          </Button>
        </FixedBar>
      </Container>
    </form>
  );
};

FormSiteProperties.defaultProps = defaultProps;
FormSiteProperties.propTypes = propTypes;

export default reduxForm({
  form: 'FormSiteProperties',
  onSubmitSuccess: (res, dispatch) => {
    // Reinicializa o formulário com os valores
    dispatch(initialize('FormSiteProperties', res.data, false));
  },
  keepDirtyOnReinitialize: true,
  enableReinitialize: true,
})(FormSiteProperties);
