import api from 'services';
import { responseMessage } from 'lib/service-helpers';

/**
 * @typedef {Object} Properties
 * @property {string} id
 */

/**
 * @typedef {Object} FeaturedItemList
 * @property {string} id
 * @property {string} title
 * @property {number} order
 * @property {string} created_at
 * @property {string} updated_at
 * @property {string} real_estate_id
 */

/**
 * @typedef {Object} FeaturedListsResponse
 * @property {boolean} is_featured_list_shown
 * @property {FeaturedItemList[]} featured_lists
 */

/**
 * Retorna a lista de Galerias
 * @returns {Promise<FeaturedListsResponse>}
 */
export const getFeaturedLists = () =>
  api.getList('settings/sites/featured-lists');

/**
 * @typedef {Object} FeaturedItemDetail
 * @property {string} id
 * @property {string} title
 * @property {number} order
 * @property {Properties[]} properties
 * @property {string} created_at
 * @property {string} updated_at
 * @property {string} real_estate_id
 */

/**
 * Retorna o conteúdo de uma galeria
 * @param {string} id
 * @returns {Promise<FeaturedItemDetail>}
 */
export const getFeaturedList = (id) =>
  api.getOne('settings/sites/featured-lists', id);

/**
 * Altera ou adiciona uma galeria
 * @param {Object} params
 * @param {string} [params.id]
 * @param {string} params.title
 * @param {number} params.order
 * @param {string} params.real_estate_id
 * @returns {Promise<void>}
 */
export const upsertFeaturedList = ({ id, title, order, real_estate_id }) => {
  if (id) {
    return api
      .update('settings/sites/featured-lists', {
        id,
        title,
        order,
        real_estate_id,
      })
      .then(responseMessage('Galeria salva'));
  }

  return api
    .create('settings/sites/featured-lists', {
      title,
      order,
      real_estate_id,
    })
    .then(responseMessage('Galeria adicionada'));
};

/**
 * Remove uma galeria
 * @param {string} id
 */
export const removeFeaturedList = (id) =>
  api
    .delete('settings/sites/featured-lists', id)
    .then(responseMessage('Galeria removida'));

export const updateFeaturedListsProperties = ({ id, property_ids }) => {
  console.log(id, property_ids);
  return api.update(`settings/sites/featured-lists/${id}/properties`, {
    properties: property_ids,
  });
};

/**
 * Adiciona imóveis na galeria
 * @param id
 * @param {Array[string]} property_ids
 */
export const addFeaturedListsProperties = ({ id, property_ids }) => {
  return api.create(`settings/sites/featured-lists/${id}/properties`, {
    properties: property_ids,
  });
};
