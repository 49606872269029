import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { connect } from 'react-redux';
// Ducks
// import { } from '../../../ducks/';
import { getModalUserValues, handleFormSubmit } from '../../../modules/modalUser';
import { openModalSupport } from 'modules/modal';
// components do sistema
import { ModalTemplate } from '../../../components/Modal';
import UserForm from './components/UserForm';
import { STATUS_CRECI } from '../../../constants/constants';

const defaultProps = {
  handleFormSubmit: () => {}
};

const propTypes = {
  handleFormSubmit: PropTypes.func,
  modalType: PropTypes.string.isRequired,
  modalConfig: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

class ModalUser extends Component {
  componentDidMount() {
    const { id, getModalUserValues } = this.props;

    getModalUserValues(id);
  }

  get isCreciVerified() {
    const { initialValues: user } = this.props;

    return user.creci_status === STATUS_CRECI.VERIFIED;
  }

  render() {
    const {
      id,
      modalConfig,
      isOpen,
      handleClose,
      modalType,
      currentUser,
      initialValues,
      modalUser,
      handleFormSubmit
    } = this.props;

    return (
      <Modal
        {...modalConfig}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={handleClose}
      >
        <ModalTemplate
          isLoading={modalUser.meta.isFetching}
          title={id ? 'Editar Usuário' : 'Cadastrar Usuário'}
          handleClose={handleClose}
        >
          {this.isCreciVerified && (
            <>
              <span
                className="h-color--danger h-margin-bottom--15"
                style={{ display: 'inline-block' }}
              >
                Esse corretor já foi verificado. Para alterar seu Nome ou CRECI,
                solicite ao suporte{' '}
                <button
                  className="h-link"
                  onClick={this.props.openModalSupport}
                >
                  clicando aqui
                </button>
              </span>
            </>
          )}
          <UserForm
            userId={id}
            initialValues={initialValues}
            isAdmin={currentUser.admin}
            handleClose={handleClose}
            onSubmit={handleFormSubmit}
          />
        </ModalTemplate>
      </Modal>
    );
  }
}

ModalUser.defaultProps = defaultProps;
ModalUser.propTypes = propTypes;

const mapStateToProps = state => ({
  currentUser: state.login.currentUser,
  modalUser: state.modalUser,
  initialValues: state.modalUser.formData
});
const mapDispatchToProps = {
  handleFormSubmit,
  openModalSupport,
  getModalUserValues
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalUser);
