import React from 'react';
import { Field } from 'redux-form';
import { Input } from 'components/Form';
import { MdInfo } from 'react-icons/md';

export default function FieldReference() {
  return (
    <Field
      xs={3}
      label={
        <>
          Código de referência <span className="h-color--danger"> *</span>{' '}
          <MdInfo
            data-tip="Você não precisa<br/> alterar este código."
            className="h-color--secondary"
          />
        </>
      }
      name="reference"
      component={Input}
      maxLength={19}
    />
  );
}
