import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { ModalTemplate } from 'components/Modal';
import Form from './components/Form';
import { getOne, updateOrCreate } from 'services/sites/whatsapp-contact';
import { useDispatch } from 'react-redux';
import { initialize } from 'redux-form';

function ModalWhatsappUserStore({
  modalConfig,
  isFetching,
  isOpen,
  modalType,
  handleClose,
  id,
  onSubmitSuccess,
}) {
  const dispatch = useDispatch();

  const fetchData = async () => {
    const res = await getOne(id);

    dispatch(initialize('FormModalWhatsapp', res.data));
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  return (
    <Modal
      {...modalConfig}
      isFetching={isFetching}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate
        title={id ? 'Editar usuário' : 'Cadastrar Usuário'}
        handleClose={handleClose}
      >
        <Form
          id={id}
          handleClose={handleClose}
          onSubmit={updateOrCreate}
          onSubmitSuccess={(...rest) => {
            if (onSubmitSuccess) onSubmitSuccess(...rest);
            handleClose();
          }}
        />
      </ModalTemplate>
    </Modal>
  );
}

export default ModalWhatsappUserStore;
