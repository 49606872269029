import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Field, reduxForm } from 'redux-form';
import { FieldBool, Input } from 'components/Form';
import { ModalFooter } from 'components/Modal';
import Button from 'components/Button';
import PreviewStripe from 'pages/PropertyStore/pages/Publish/components/PreviewStripe';

function FormProperty({ handleSubmit, handleClose, submitting }) {
  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Field xs={6} label="Referência" name="reference" component={Input} />
        <FieldBool xs={6} label="Destaque?" name="is_featured" />
      </Row>
      <Row>
        <Col xs={12}>
          <PreviewStripe />
        </Col>
      </Row>
      <ModalFooter>
        <Button type="button" color="white" onClick={handleClose}>
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success" disabled={submitting}>
          Editar
        </Button>
      </ModalFooter>
    </form>
  );
}

export default reduxForm({
  form: 'FormProperty',
  enableReinitialize: true,
})(FormProperty);
