import React from 'react';
import BoxHelp from 'components/BoxHelp';

// import { Wrapper } from './styles';

function BoxHelpEmail() {
  return (
    <BoxHelp
      storageKey="email"
      mainTopic={{
        text: 'Diferença entre Conta Redirecionada e Conta Local',
        videoUrl: 'https://www.youtube.com/watch?v=i7RjhKlGkWc',
      }}
      topics={[
        {
          text: 'Criar e conectar email ao Gmail',
          videoUrl: 'https://www.youtube.com/watch?v=-qc9ZFY6h5w',
          link: 'https://scribehow.com/shared/Conectar_com_o_Gmail__Jn9fawAaRaanbgo5n2HmEA',
        },
        {
          text: 'Criar e conectar email ao Hotmail/Outlook',
          videoUrl: 'https://www.youtube.com/watch?v=MLyE1grZlRw',
          link: 'https://scribehow.com/shared/Conectar_com_o_Hotmail__dHhIL0I_RnuBSpSm8i7-0w',
        },
        {
          text: 'Criar conta de email redirecionada ou local',
          link: 'https://scribehow.com/page/Como_criar_conta_de_E-mail__KPXQccH_SA-u8frYWQ_0nw',
        },
      ]}
      helpLink="https://tecimob.com.br/ajuda/ajuda-emails/"
      style={{ marginTop: '40px' }}
    />
  );
}

export default BoxHelpEmail;
