import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
// Components
import Loading from 'components/Loading';
import FormSlides from '../../components/FormSlides';
import MainTitle from 'components/MainTitle';
// Modules
import { fetchSlides, slidesSelectors } from 'modules/components/slides';
// Service
import * as slidesService from 'services/sites/slides';
import * as propertiesService from 'services/properties';
import { removeTags } from 'lib/text';

/**
 * Retorna a área total pelo imóvel
 * @param property
 * @return {null}
 */
const getTotalArea = (property) => {
  try {
    const { value, measure } = get(property, 'areas.total_area');

    return `${value} ${measure} (Área total)`;
  } catch {
    return null;
  }
};

const getPropertyFileUrl = (property) => {
  try {
    return (property.images || [{}])[0].file_url;
  } catch {
    return '';
  }
};

const getLinkWithoutUserId = (originalLink) => {
  try {
    if (!originalLink) return null;
    const link = new URL(originalLink);
    link.searchParams.delete('user_id');

    return link;
  } catch {
    return null;
  }
};

/**
 * Transforma um imóvel para dados correspondentes a um slide
 * @param property
 */
const propertyToSlide = (property) => {
  const file_url = getPropertyFileUrl(property);

  return {
    // Passa o id do imóvel para salvar a imagem
    image_id: property?.images?.[0]?.id,

    // Informações padrão
    title: property.title_formatted,
    address: property.address_formatted,
    price: property.calculated_price,

    description: removeTags(property.description),

    // Link
    link: getLinkWithoutUserId(property.site_link),
    opens_link_on_page: false,

    // Expiração
    has_expiration: false,

    // Comodos do imóvel
    bedrooms: get(property, 'rooms.bedroom.value'),
    suites: get(property, 'rooms.suite.value'),
    garages: get(property, 'rooms.garage.value'),
    file_url,

    // Areas
    total_area: getTotalArea(property),

    // sempre mostra o usuário por que não tem
    // como um imóvel não ter usuário atrelado
    is_user_shown: true,
    user_id: property.user_id,
  };
};

class SlideForm extends React.Component {
  static propTypes = {};
  static defaultProps = {};

  state = {
    formData: {},
    images: null,
    isFetching: true,
  };

  get slideId() {
    try {
      return this.props.match.params.slide_id;
    } catch {
      return null;
    }
  }

  get propertyId() {
    try {
      return this.props.match.params.property_id;
    } catch {
      return null;
    }
  }

  async componentDidMount() {
    // Busca todos os componentes de slide
    await this.props.fetchSlides();

    // Se tiver o parametro slide_id
    // Significa que ele está editando algum slide
    if (this.slideId) {
      await this.fetchSlideData();
    }

    // Se tiver o parametro de property_id
    if (this.propertyId) {
      await this.fetchSlideProperty();
    }

    this.setState({ ...this.state, isFetching: false });
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      match: {
        params: { property_id: currentPropertyId },
      },
    } = this.props;
    const {
      match: {
        params: { property_id: prevPropertyId },
      },
    } = prevProps;

    if (currentPropertyId !== prevPropertyId) {
      await this.fetchSlideProperty();
    }
  }

  request = () => {
    this.setState({ ...this.state, isFetching: true });
  };

  receive = (data) => {
    this.setState({ ...this.state, formData: data, isFetching: false });
  };

  fetchSlideData = async () => {
    this.request();

    try {
      // Busca as informações do slide no servidor
      const { data } = await slidesService.getOne({ id: this.slideId });

      // Recebe as informações do slide
      this.receive({
        ...data,
        property_id: this.propertyId,
        link: getLinkWithoutUserId(data.link),
      });
    } finally {
      this.setState({ ...this.state, isFetching: false });
    }
  };

  fetchSlideProperty = async () => {
    this.request();

    try {
      // Pega o imóvel em si
      const { data: property } = await propertiesService.getOne(
        this.propertyId
      );

      // Seta as imagens
      this.setState({ ...this.state, images: property.images });

      // Receive
      this.receive(propertyToSlide(property));
    } finally {
      this.setState({ ...this.state, isFetching: false });
    }
  };

  onSubmit = (values) =>
    values.id ? slidesService.update(values) : slidesService.create(values);

  onSubmitSuccess = (res, dispatch) => {
    this.props.history.push('/site/layout/style/slides');

    const newUrl = `/properties/search?_openModalProperty=${this.propertyId}`;
    this.props.history.push(newUrl);
    window.location.reload();
  };

  render() {
    const { components } = this.props;
    const { formData, isFetching, images } = this.state;

    return (
      <>
        <MainTitle
          title="Slides"
          text="Crie seus slides a partir da nossa ferramenta ou envie seus slides prontos."
        />
        {isFetching ? (
          <Loading isVisible />
        ) : (
          <FormSlides
            isEditting={!!this.state.formData.id}
            images={images}
            components={components}
            onSubmit={this.onSubmit}
            onSubmitSuccess={this.onSubmitSuccess}
            initialValues={{
              type: 'SlideHome1',
              has_expiration: false,
              is_user_shown: false,
              opens_link_on_page: false,
              property_id: this.propertyId,
              ...formData,
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  components: slidesSelectors.getAll(state),
});

const mapDispatchToProps = {
  fetchSlides,
};

export default connect(mapStateToProps, mapDispatchToProps)(SlideForm);
