import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import { MdHelp } from 'react-icons/md';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
// Components
import Button from 'components/Button';
import Container from 'components/Container';
import FixedBar from 'components/FixedBar';
import {
  CarouselSelect,
  FileInput,
  Input,
  InputDate,
  Property,
  Select,
  Textarea,
} from 'components/Form';
// Containers
import FieldSelectUser from 'containers/FieldSelectUser';
// Constants
import { BOOL } from 'constants/options';
import { parseValueBool, positiveNumbers } from 'lib/formHelpers';
import { imageChangeSize } from 'lib/image-helpers';
// Assets
import img from 'assets/img/photo-colored.svg';

const defaultProps = {
  users: null,
  isFetchingUsers: false,

  components: null,
  isFetchingComponents: false,
};

const propTypes = {
  components: PropTypes.arrayOf(PropTypes.object),
  isFetchingComponents: PropTypes.bool,

  handleSubmit: PropTypes.func.isRequired,
};

const FormSlides = ({
  isEditting,
  components,
  isFetchingComponents,

  is_user_shown,
  has_expiry_date,

  handleSubmit,
  submitting,
  pristine,
  file_url,
  images,
  change,
  history,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="type"
        component={CarouselSelect}
        options={components}
        className="h-margin-bottom--15"
      />
      <Container>
        <Row>
          <Col
            xs={5}
            className="FormSlides__image"
            styles={{ flexDirection: 'column' }}
          >
            {images ? (
              <div className="CarouselSelect--bottom">
                <Field
                  name="image_id"
                  valueKey="id"
                  srcKey="file_url"
                  options={images}
                  component={CarouselSelect}
                />
              </div>
            ) : (
              <Field
                multiple={false}
                height="605"
                name="image"
                component={FileInput}
                className="DropImage--height"
              >
                {({ input }) => {
                  let fileUrl = file_url || '';

                  if (input.value) {
                    fileUrl = input.value.preview;
                  }

                  if (fileUrl) {
                    return (
                      <div
                        className="DropImage DropImage--overlay h-hover"
                        style={{
                          backgroundImage: `url(${imageChangeSize(
                            fileUrl,
                            'small',
                            'cover'
                          )})`,
                        }}
                      >
                        <div className="DropImage__content h-flex h-hover__show">
                          <Button
                            type="button"
                            color="white"
                            colorText="secondary"
                            className="DropImage__button"
                          >
                            Trocar Imagem
                          </Button>
                        </div>
                      </div>
                    );
                  }

                  return (
                    <div className="DropImage">
                      <div
                        className="DropImage__content"
                        style={{ width: '250px' }}
                      >
                        <img src={img} alt="" className="DropImage__icon" />
                        <p className="DropImage__text">
                          Envie uma imagem para o seu slide.
                          <br />
                          (Recomendado: 800x500)
                        </p>
                        <Button
                          type="button"
                          color="secondary"
                          className="DropImage__button"
                        >
                          Enviar
                        </Button>
                      </div>
                    </div>
                  );
                }}
              </Field>
            )}
          </Col>
          <Col xs={7}>
            <Row>
              <Field
                xs={isEditting ? 12 : 6}
                label="Título"
                name="title"
                component={Input}
              />
              {!isEditting && (
                <Property
                  filter={{
                    having: 'images',
                  }}
                  label="Imóvel"
                  name="property_id"
                  xs={6}
                  onChange={(property, propertyId) => {
                    history.replace(
                      `/site/content-site/slides/property/${propertyId}`
                    );
                  }}
                />
              )}
            </Row>
            <Row>
              <Field xs={6} label="Endereço" name="address" component={Input} />
              <Field xs={6} label="Preço" name="price" component={Input} />
            </Row>
            <Row>
              <Field
                canExceed
                maxLength={400}
                type="simple"
                xs={12}
                label="Descrição"
                name="description"
                component={Textarea}
              />
            </Row>
            <Row>
              <Field
                xs={3}
                type="number"
                label="Dormitórios"
                name="bedrooms"
                component={Input}
                parse={positiveNumbers}
              />
              <Field
                xs={3}
                type="number"
                label="Sendo Suítes"
                name="suites"
                component={Input}
                parse={positiveNumbers}
              />
              <Field
                xs={3}
                type="number"
                label="Garagens"
                name="garages"
                component={Input}
                parse={positiveNumbers}
              />
              <Field xs={3} label="Área" name="total_area" component={Input} />
            </Row>
            <Row>
              <Field
                xs={5}
                buttonTemplate
                canRemoveSelf={false}
                label={
                  <>
                    Mostrar corretor{' '}
                    <MdHelp
                      className="h-color--secondary"
                      data-html
                      data-tip="Mostra o nome, foto e CRECI<br/> do corretor no slide."
                      data-effect="solid"
                    />
                  </>
                }
                name="is_user_shown"
                component={Select}
                options={BOOL}
                onChange={() => {
                  change('user_id', null);
                }}
              />
              <FieldSelectUser disabled={!is_user_shown} xs={7} />
            </Row>
            <Row>
              <Field
                xs={8}
                name="link"
                label="Link"
                placeholder="Cole o Link"
                component={Input}
              />
              <Field
                xs={4}
                name="opens_link_on_page"
                label="Abrir link na:"
                placeholder=""
                component={Select}
                options={[
                  { label: 'Mesma página', value: true },
                  { label: 'Outra página', value: false },
                ]}
                parse={parseValueBool}
              />
            </Row>
            <Row>
              <Field
                xs={5}
                buttonTemplate
                canRemoveSelf={false}
                label={
                  <>
                    Data de expiração?{' '}
                    <MdHelp
                      className="h-color--secondary"
                      data-html
                      data-tip="Seu slide será desativado <br />automaticamente na data desejada"
                      data-effect="solid"
                    />
                  </>
                }
                name="has_expiration"
                component={Select}
                options={BOOL}
              />
              <Field
                xs={7}
                buttonTemplate
                canRemoveSelf={false}
                label="Data de expiração do slide:"
                name="expiry_date"
                component={InputDate}
                disabled={!has_expiry_date}
              />
            </Row>
          </Col>
        </Row>
      </Container>
      <FixedBar style={{ left: 240 }}>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success" disabled={submitting}>
          Salvar Slide
        </Button>
      </FixedBar>
    </form>
  );
};

FormSlides.defaultProps = defaultProps;
FormSlides.propTypes = propTypes;

const selector = formValueSelector('FormSlides');

const mapStateToProps = (state) => ({
  is_user_shown: selector(state, 'is_user_shown'),
  has_expiry_date: selector(state, 'has_expiration'),
  file_url: selector(state, 'file_url'),
});

const validate = (values) => {
  const maxLength = 400;

  if (values.description && values.description.length > maxLength) {
    values.description = values.description.substring(0, maxLength);
  }
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  reduxForm({
    form: 'FormSlides',
    keepDirtyOnReinitialize: true,
    enableReinitialize: true,
    validate,
  })
)(FormSlides);
