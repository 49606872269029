import React from 'react';
import _ from 'lodash';
import Tooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';
// Components
import { Input, SelectMultiple } from 'components/Form';
import Button from 'components/Button';
import { ModalFooter, ModalTemplate } from 'components/Modal';
// Services
import * as peopleService from 'services/people';
import * as cepsService from 'services/ceps';
// Containers
import FieldSelectUser from 'containers/FieldSelectUser';
// Modules
import { addGroup } from 'modules/peopleGroups';
import {
  addReceptionSource,
  getReceptionSources,
  receptionSourcesSelector,
} from 'modules/receptionSources';
// Helpers
import { normalizeDate, parseSelect } from 'lib/formHelpers';

import validate from './validate';
import Can from 'containers/Can';
import { PERSON_PERMISSIONS, USER_PERMISSIONS } from 'constants/rules';
import FieldPhones from 'containers/FieldPhones';
import { Box, BoxLeft, BoxRight, Form, SeeFullForm, Wrapper } from './styles';

const propTypes = {
  personId: PropTypes.object,
  groups_id: PropTypes.any,
  stateId: PropTypes.string,
  cityId: PropTypes.string,
  config: PropTypes.shape({
    checkGroupOwner: PropTypes.bool,
  }),
  typePerson: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  peopleGroups: PropTypes.arrayOf(PropTypes.object),
  realtors: PropTypes.arrayOf(PropTypes.object),
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  changeFieldValue: PropTypes.func.isRequired,
};
const defaultProps = {
  config: {
    checkGroupOwner: false,
  },
  personId: null,
  groups_id: null,
  stateId: null,
  cityId: null,
  typePerson: null,
  realtors: [{}],
  peopleGroups: [{}],
};

class PersonForm extends React.Component {
  componentDidMount() {
    // Pega as origens de captação
    this.props.getReceptionSources();

    Tooltip.rebuild();
  }

  get canEdit() {
    const { person } = this.props;
    const hasPermissions = person && person.permissions;

    if (hasPermissions) {
      return person.permissions.includes(PERSON_PERMISSIONS.EDIT);
    }

    return true;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { config, peopleGroups } = this.props;
    const { peopleGroups: nextPeopleGroups } = nextProps;

    if (config?.checkGroupByName && peopleGroups !== nextPeopleGroups) {
      return nextPeopleGroups.forEach((group) => {
        if (group.name === config?.checkGroupByName) {
          this.props.change('groups_id', [group.id]);
        }
      });
    }

    if (config.checkGroupOwner && peopleGroups !== nextPeopleGroups) {
      return nextPeopleGroups.forEach((group) => {
        if (group.is_owner) {
          this.props.change('groups_id', [group.id]);
        }
      });
    }
  }

  isLegalPerson = () => {
    const { typePerson } = this.props;
    return typePerson === 2 || typePerson === '2';
  };

  handleChangeTypePerson = (e) => {
    const { changeFieldValue } = this.props;

    changeFieldValue('cpf_cnpj', null);
    changeFieldValue('rg_ie', null);

    if (e.target.value === 2 || e.target.value === '2') {
      changeFieldValue('spouse_name', null);
      changeFieldValue('spouse_cpf', null);
    }
  };

  handleNewType = (option) => {
    const { addGroup, groups_id, changeFieldValue } = this.props;
    addGroup(option.name).then((newOption) => {
      const value = groups_id || [];
      changeFieldValue('groups_id', [...value, newOption.id]);
    });
  };

  handleNewResource = (option) => {
    const { changeFieldValue } = this.props;

    this.props.addReceptionSource(option.name).then((newOption) => {
      changeFieldValue('reception_source_id', newOption.id);
    });
  };

  handleChangeCountries = (el, value) => {
    if (value) {
      this.props.getStates(value);
    }
    this.props.changeFieldValue('state_id', '');
    this.props.changeFieldValue('city_id', '');
    this.props.changeFieldValue('neighborhood_id', '');
  };

  handleChangeStates = (el, value) => {
    if (value) {
      this.props.getCities(value);
    }
    this.props.changeFieldValue('city_id', '');
    this.props.changeFieldValue('neighborhood_id', '');
  };

  handleChangeCities = (el, value) => {
    if (value) {
      this.props.getNeighborhoods(value);
    }

    this.props.changeFieldValue('neighborhood_id', '');
  };

  // TODO MELHORAR
  handleBlurEmail = (el, value) => {
    if (!value || this.props.isEditting) return null;

    return peopleService
      .getAll({ filter: { email: value }, include: 'user' })
      .then(({ data: people }) => {
        if (people.length > 0) {
          this.props.setHasPerson(people[0]);
        }
      });
  };

  // TODO MELHORAR
  handleBlurCPF_CNPJ = (el, value) => {
    if (!value || this.props.isEditting) return null;

    return peopleService
      .getAll({ filter: { cpf_cnpj: value }, include: 'user' })
      .then(({ data: people }) => {
        if (people.length > 0) {
          this.props.setHasPerson(people[0]);
        }
      });
  };

  handleBlurCellphoneNumber = (el, value) => {
    if (!value || this.props.isEditting) return null;

    return peopleService
      .getAll({ filter: { cellphone_number: value }, include: 'user' })
      .then(({ data: people }) => {
        if (people.length > 0) this.props.setHasPerson(people[0]);
      });
  };

  setLocation = (data) => {
    const { change, getStates, getCities, getNeighborhoods } = this.props;

    const cityId = _.get(data, 'city.id', null);
    const countryId = _.get(data, 'country.id', null);
    const neighborhoodId = _.get(data, 'neighborhood.id', null);
    const stateId = _.get(data, 'state.id', null);
    const streetAddress = data.street_address;

    if (countryId) {
      getStates(countryId);
      change('country_id', countryId);
    }

    if (stateId) {
      getCities(stateId);
      change('state_id', stateId);
    }

    if (cityId) {
      getNeighborhoods(cityId);
      change('city_id', cityId);
    }

    if (streetAddress) change('address_street', streetAddress);

    if (neighborhoodId) change('neighborhood_id', neighborhoodId);
  };

  fetchCEP = (cep) => {
    return cepsService.getLocation({ cep }).then(({ data }) => {
      this.setLocation(data);
    });
  };

  handleChangeCep = (e, value, prevValue) => {
    if (!value || value === prevValue) return false;
    if (value.length === 9) {
      this.fetchCEP(value);
    }
    return false;
  };

  render() {
    const {
      person,
      isLoading,
      isEditting,
      peopleGroups,
      submitting,
      handleSubmit,
      handleClose,
      receptionSources,
    } = this.props;

    return !this.canEdit ? (
      <ModalTemplate
        title="Sem permissão para editar esse cliente"
        handleClose={handleClose}
      />
    ) : (
      <ModalTemplate
        isLoading={isLoading}
        handleClose={handleClose}
        renderHeader={() => (
          <header className="Modal__header Modal__header--custom h-flex h-flex--center-center">
            <h4 className="Modal__title">
              {isEditting ? 'Editar Cliente' : 'Cadastrar Cliente'}
            </h4>
          </header>
        )}
      >
        <Form>
          <Wrapper>
            <BoxLeft>
              <Row>
                <Field
                  refName={(ref) => {
                    this.fieldName = ref;
                  }}
                  xs={12}
                  required
                  name="name"
                  label="Nome"
                  component={Input}
                />
                <Field
                  xs={12}
                  type="email"
                  name="email"
                  label="E-mail"
                  component={Input}
                  onBlur={this.handleBlurEmail}
                />
                <Field
                  xs={12}
                  name="birth_date"
                  label="Data de nascimento"
                  component={Input}
                  format={normalizeDate}
                />
                <Can
                  run={USER_PERMISSIONS.EDIT_PEOPLE_GROUPS}
                  yes={() => (
                    <Field
                      xs={12}
                      required
                      autoFocus
                      creatable
                      name="groups_id"
                      label="Categoria"
                      options={peopleGroups}
                      component={SelectMultiple}
                      onNewOptionClick={this.handleNewType}
                      valueKey="id"
                      labelKey="name"
                      parse={parseSelect('id')}
                    />
                  )}
                  no={() => (
                    <Field
                      xs={12}
                      required
                      autoFocus
                      name="groups_id"
                      label="Categoria"
                      options={peopleGroups}
                      component={SelectMultiple}
                      valueKey="id"
                      labelKey="name"
                      parse={parseSelect('id')}
                    />
                  )}
                />
                <Can
                  run={USER_PERMISSIONS.EDIT_RECEPTION_SOURCES}
                  yes={() => (
                    <Field
                      required
                      xs={12}
                      creatable
                      multi={false}
                      autoFocus
                      name="reception_source_id"
                      label="Origem da captação"
                      options={receptionSources}
                      component={SelectMultiple}
                      onNewOptionClick={this.handleNewResource}
                      valueKey="id"
                      labelKey="name"
                      parse={parseSelect('id')}
                    />
                  )}
                  no={() => (
                    <Field
                      required
                      xs={12}
                      multi={false}
                      autoFocus
                      name="reception_source_id"
                      label="Origem da captação"
                      options={receptionSources}
                      component={SelectMultiple}
                      valueKey="id"
                      labelKey="name"
                      parse={parseSelect('id')}
                    />
                  )}
                />
              </Row>

              <SeeFullForm
                type="button"
                onClick={handleSubmit((values) =>
                  this.props.onSubmit({
                    ...values,
                    isFullForm: true,
                  })
                )}
              >
                Ver cadastro completo
              </SeeFullForm>
            </BoxLeft>
            <BoxRight>
              <Box>
                {!isEditting || !person.user_id ? (
                  <FieldSelectUser label="Corretor responsável" />
                ) : (
                  <Can
                    run={PERSON_PERMISSIONS.CHANGE_REALTOR}
                    permissions={person.permissions}
                  >
                    <FieldSelectUser label="Corretor responsável" />
                  </Can>
                )}
              </Box>
              <Box>
                <FieldPhones
                  person={person}
                  onHasPhone={({ person: currentPerson }) => {
                    this.props.setHasPerson(currentPerson);
                  }}
                />
              </Box>
            </BoxRight>
          </Wrapper>
          <ModalFooter
            style={{
              position: 'sticky',
              bottom: '0',
              padding: '20px',
              margin: '-5px -18px -20px',
              background: '#fff',
            }}
          >
            <Button color="white" colorText="primary" onClick={handleClose}>
              Cancelar
            </Button>
            <span className="h-flex__cell--grow" />
            <Button
              onClick={handleSubmit}
              type="button"
              color="success"
              disabled={submitting}
            >
              {isEditting ? 'Editar' : 'Cadastrar'}
            </Button>
          </ModalFooter>
        </Form>
      </ModalTemplate>
    );
  }
}

PersonForm.defaultProps = defaultProps;
PersonForm.propTypes = propTypes;

const selector = formValueSelector('personForm');

const mapStateToProps = (state, ownProps) => {
  return {
    groups_id: selector(state, 'groups_id'),
    typePerson: selector(state, 'type'),
    receptionSourceId: selector(state, 'reception_source_id'),
    peopleGroups: state.peopleGroups.groups,
    realtors: state.realtors.realtors,
    initialValues: {
      ...state.modalPersonAdd.formData,
      ...ownProps.person,
      groups_id: state?.modalPersonAdd?.formData?.groups?.map(
        (group) => group.id
      ),
    },
    receptionSources: receptionSourcesSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  addGroup: (typeName) => dispatch(addGroup(typeName)),
  changeFieldValue: (field, value) => {
    dispatch(change('personFormSimple', field, value));
  },
  getReceptionSources: (params) => dispatch(getReceptionSources(params)),
  addReceptionSource: (name) => dispatch(addReceptionSource(name)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'personFormSimple',
    validate,
    enableReinitialize: true,
  })(PersonForm)
);
