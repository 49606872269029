import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
// Modules
import { fetchAccounts, localAccountsSelector, removeAccount } from './module';
import { fetchAlias } from '../Alias/module';
import {
  accountsSelector,
  fetchAccounts as fetchRedirectedAccounts,
} from '../Accounts/module';

import {
  closeModal,
  openModalAddEmailAccount,
  openModalContractExtra,
  openModalContractRemove,
  openModalDailyDebits,
  openModalEmailAlias,
  openModalEmailBoxRedirected,
  openModalEmailPassword,
} from 'modules/modal';
// Components
import MainTitle from 'components/MainTitle';
import Container from 'components/Container';
import Button from 'components/Button';
import TableAccounts from './components/TableAccounts';
import Alias from '../Alias';
// Containers
import { openConfirmation } from 'containers/ModalConfirmation/module';
import { changePreview1 } from 'services/financialv2/contracts';
import { usePreview1 } from 'hooks/api/contracts';
import Alert from 'components/Alert';
import SAlert from 'react-s-alert';

const LocalAccounts = (props) => {
  const { currentItems, fetchCurrentItems } = props;
  const { fetchPreview1, ITEM_TYPES } = usePreview1();
  const dispatch = useDispatch();

  const {
    data,
    fetchAccounts,
    fetchAlias,
    removeAccount,
    openModalAddEmailAccount,
    openModalContractRemove,
    openModalEmailAlias,
    openModalEmailBoxRedirected,
    openModalEmailPassword,
    openConfirmation,
    fetchRedirectedAccounts,
    redirectedAccounts,
    isFetching,
  } = props;

  useEffect(() => {
    fetchAccounts();
  }, [fetchAccounts]);

  const handleUpdatePassword = (username) => {
    openModalEmailPassword(username);
  };

  const handleCreateAlias = (username) => {
    openModalEmailAlias(username, {
      onSubmitSuccess: () => {
        fetchAlias();
      },
    });
  };

  const handleDelete = async (userName) => {
    const fetchChangePreview = async () => {
      const { data } = await changePreview1({
        item: ITEM_TYPES.EMAIL,
        is_adding: false,
      });
      return data;
    };

    const preview = await fetchChangePreview();

    if (!preview?.need_change_contract) {
      return openConfirmation({
        title: 'Remover caixa local',
        text: `Tem certeza que voce deseja remover a caixa ${userName}`,
        request: () =>
          removeAccount(userName, { should_change_contract: false }),
        onSuccess: () => {
          fetchAccounts();
          fetchAlias();
        },
      });
    }

    openModalContractRemove({
      item: ITEM_TYPES.EMAIL,
      onSuccess: ({ should_change_contract }) => {
        openConfirmation({
          title: 'Remover caixa local',
          text: `Tem certeza que voce deseja remover a caixa ${userName}`,
          request: () => removeAccount(userName, { should_change_contract }),
          onSuccess: () => {
            fetchAccounts();
            fetchAlias();
          },
        });
      },
    });
  };

  const openModal = () => {
    openModalAddEmailAccount({
      onSubmitSuccess: () => {
        fetchAccounts();
      },
    });
  };

  const openModalRedirectAccounts = () => {
    openModalEmailBoxRedirected({
      onSubmitSuccess: () => {
        fetchRedirectedAccounts();
      },
    });
  };

  const handleOpenModalManage = async () => {
    const { data: preview } = await fetchPreview1({
      item: ITEM_TYPES.EMAIL,
      is_adding: false,
    });

    dispatch(
      openModalContractExtra({
        item: ITEM_TYPES.EMAIL,
        currentItems,
        preview,
        onSuccess: () => {
          fetchCurrentItems();
          SAlert.success('Contrato alterado');
          dispatch(closeModal());
        },
      })
    );
  };

  const hasRedirectedAccounts = !!redirectedAccounts.length;

  if (data.length === 0) return null;

  return (
    <>
      <MainTitle
        title="Contas locais"
        text="Crie contas locais para o seu domínio"
      >
        <Button
          tagName="a"
          color="primary"
          href="https://tecimob.com.br/ajuda/category/email/"
          target="_blank"
        >
          Ajuda
        </Button>
        {!hasRedirectedAccounts && (
          <Button
            color="white"
            colorText="secondary"
            onClick={openModalRedirectAccounts}
          >
            Criar caixa redirecionada
          </Button>
        )}
        <Button color="secondary" onClick={openModal}>
          Criar caixa local
        </Button>
      </MainTitle>
      {currentItems?.emails_quantity > 0 && (
        <Alert color="secondary">
          <div className="h-flex">
            <div className="h-flex__cell--grow">
              Você está utilizando <strong>{data.length}</strong> das{' '}
              <strong>{currentItems?.emails_quantity}</strong> caixas de e-mail
              local contratadas.
            </div>
            <button
              className="h-link h-flex__cell--shrink"
              onClick={handleOpenModalManage}
            >
              Gerenciar total de assentos contratados
            </button>
          </div>
        </Alert>
      )}
      <Container padding style={{ marginTop: '20px' }}>
        <TableAccounts
          data={data}
          isFetching={isFetching}
          handleUpdatePassword={handleUpdatePassword}
          handleCreateAlias={handleCreateAlias}
          handleDelete={handleDelete}
        />
      </Container>
      <Alias />
    </>
  );
};

LocalAccounts.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  fetchAccounts: PropTypes.func.isRequired,
  fetchAlias: PropTypes.func.isRequired,
  removeAccount: PropTypes.func.isRequired,
  openModalAddEmailAccount: PropTypes.func.isRequired,
  openModalContractRemove: PropTypes.func.isRequired,
  openModalEmailAlias: PropTypes.func.isRequired,
  openModalEmailBoxRedirected: PropTypes.func.isRequired,
  openModalEmailPassword: PropTypes.func.isRequired,
  openConfirmation: PropTypes.func.isRequired,
  fetchRedirectedAccounts: PropTypes.func.isRequired,
  redirectedAccounts: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

LocalAccounts.defaultProps = {
  data: [],
};

const mapStateToProps = (state) => ({
  data: localAccountsSelector.getAll(state),
  redirectedAccounts: accountsSelector.getAll(state),
  isFetching: localAccountsSelector.isFetching(state),
});

const mapDispatchToProps = {
  fetchAccounts,
  fetchAlias,
  removeAccount,
  openModalEmailBoxRedirected,
  openModalAddEmailAccount,
  openModalContractRemove,
  openModalEmailAlias,
  openModalEmailPassword,
  openConfirmation,
  fetchRedirectedAccounts,
  openModalDailyDebits,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocalAccounts);
