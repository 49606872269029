import React from 'react';
import Countdown from 'pages/Dashboard/components/Countdown';
import AlertFrozenAccount from 'pages/Dashboard/containers/AlertFrozenAccount';
// import { Container } from './styles';

const DashboardAlerts = () => {
  return (
    <>
      <Countdown />
      {/*<AlertCreciContainer />*/}
      <AlertFrozenAccount />
      {/*<CreciAdmin />*/}
    </>
  );
};

export default DashboardAlerts;
