import React, { useEffect, useState } from 'react';
import MainTitle from 'components/MainTitle';
import { useRequest } from 'hooks/useRequest';
import * as visitScheduleService from 'services/sites/visitSchedule';
import Container from 'components/Container';
import ListOptions from 'components/ListOptions';
import Button from 'components/Button';
import { FieldBool, HourBlocks, Input, Select } from 'components/Form';
import useFormValue from 'hooks/useFormValue';
import { Field, FieldArray, reduxForm } from 'redux-form';
import FixedBar from '../../../../../../components/FixedBar';
import Reception from './component/Reception';
import Loading from 'components/Loading';
// import { Wrapper } from './styles';

const DEFAULT_VALUES = {
  same_day: true,
  has_days_limit: false,
  notification_type: 3,
  is_lead: true,
  apply_flow: true,
  schedules: [
    {
      day: 'EVERY_DAY',
      start: '08:00',
      end: '18:00',
    },
  ],
};

const Schedule = ({ handleSubmit, initialize, change, submitting }) => {
  const [isActive, setIsActive] = useState(false);

  const { data, isFetching } = useRequest({
    request: visitScheduleService.getOne,
    initialState: {},
  });

  const hasDaysLimit = useFormValue('has_days_limit');

  const handleClickDisable = async () => {
    try {
      await visitScheduleService.remove();

      setIsActive(false);
    } catch {
      setIsActive(true);
    }
  };

  useEffect(() => {
    if (data.updated_at) {
      setIsActive(true);
    }
    initialize(data);
  }, [data]);

  if (!data) return <Loading isVisible isFullScreen />;

  if (!isActive)
    return (
      <Reception
        onClick={() => {
          setIsActive(true);
          initialize(DEFAULT_VALUES);
        }}
      />
    );

  return (
    <form onSubmit={handleSubmit}>
      <MainTitle
        title="Agendar Visitas"
        text="Defina como irá funcionar o botão de agendamento de visitas no seu site."
      >
        <Button color="danger" onClick={handleClickDisable}>
          Desativar agendamento
        </Button>
      </MainTitle>

      <Container padding>
        <ListOptions>
          <ListOptions.item
            title="Permitir agendamento para o mesmo dia?"
            text="Desativado, só permite agendamento para o dia seguinte em diante"
            renderOptions={() => <FieldBool name="same_day" />}
          />
          <ListOptions.item
            title="Definir limite de dias à frente para agendamento?"
            text="Desativado, aceita qualquer data no futuro."
            renderOptions={() => {
              return (
                <>
                  {hasDaysLimit ? (
                    <div
                      className="h-flex h-flex--center-center h-margin-right--15"
                      style={{ width: '90px' }}
                    >
                      <Field
                        type="number"
                        name="days_limit"
                        component={Input}
                      />
                      <div className="h-margin-left--10">Dias</div>
                    </div>
                  ) : null}
                  <FieldBool
                    name="has_days_limit"
                    onChange={(value) => {
                      if (!value) {
                        change('days_limit', null);
                      } else {
                        change('days_limit', 1);
                      }
                    }}
                  />
                </>
              );
            }}
          />
          <ListOptions.item
            title="Notificações para o corretor"
            text="Escolha como o corretor irá receber as informações sobre os agendamentos."
            renderOptions={() => {
              return (
                <Field
                  buttonTemplate
                  name="notification_type"
                  component={Select}
                  options={[
                    { label: 'Whatsapp', value: 1 },
                    { label: 'E-mail', value: 2 },
                    { label: 'Whatsapp e E-mail', value: 3 },
                  ]}
                />
              );
            }}
          />
          <ListOptions.item
            title="Cadastrar pessoa do agendamento como um lead?"
            text="Desativando gera apenas o contato"
            renderOptions={() => {
              return <FieldBool name="is_lead" />;
            }}
          />
          <ListOptions.item
            title="Enviar agendamento para o responsável do imóvel ou seguir fluxo padrão?"
            text="Defina quem irá ser responsável pelo atendimento"
            renderOptions={() => {
              return (
                <Field
                  buttonTemplate
                  name="apply_flow"
                  component={Select}
                  options={[
                    { label: 'Fluxo padrão', value: true },
                    { label: 'Responsável', value: false },
                  ]}
                />
              );
            }}
          />
        </ListOptions>
      </Container>

      <Container padding style={{ marginTop: '20px' }}>
        <ListOptions>
          <ListOptions.item
            title="Horários permitidos"
            text="Defina os horários disponíveis para visitas"
          >
            <div className="h-margin-top--15">
              <FieldArray
                min={1}
                rerenderOnEveryChange
                name={`schedules`}
                component={HourBlocks}
                noDataConfig={{
                  title: 'Nenhum horário configurado',
                  text: 'Preencha os horários que estaram disponíveis para visita',
                }}
              />
            </div>
          </ListOptions.item>
        </ListOptions>
      </Container>
      <FixedBar style={{ left: 240 }}>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success" disabled={submitting}>
          Salvar
        </Button>
      </FixedBar>
    </form>
  );
};

export default reduxForm({
  form: 'FormVisitSchedule',
  onSubmit: (values) => {
    return visitScheduleService.update(values);
  },
})(Schedule);
