import React from 'react';
import _ from 'lodash';
import Tooltip from 'react-tooltip';
import { connect } from 'react-redux';
import {
  change,
  Field,
  formValueSelector,
  reduxForm,
  getFormValues,
} from 'redux-form';
import { MdHelp } from 'react-icons/md';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';
// Constants
import { TYPE_PERSONS } from 'constants/options';
// Components
import { Input, Select, SelectMultiple, Textarea } from 'components/Form';
import Button from 'components/Button';
import { ModalFooter, ModalTemplate } from 'components/Modal';
// Services
import * as peopleService from 'services/people';
import * as cepsService from 'services/ceps';
// Containers
import FieldSelectUser from 'containers/FieldSelectUser';
// Modules
import { addGroup } from 'modules/peopleGroups';
import { locationSelectors, locationThunks } from 'modules/modalPersonAdd';
import {
  openModalPersonAdd,
  openModalAddCity,
  openModalAddNeighborhood,
} from 'modules/modal';
import {
  addReceptionSource,
  getReceptionSources,
  receptionSourcesSelector,
} from 'modules/receptionSources';
// Helpers
import {
  normalizeCep,
  normalizeCnpj,
  normalizeCpf,
  normalizeDate,
  parseSelect,
} from 'lib/formHelpers';

import validate from './validate';
import Can from 'containers/Can';
import { PERSON_PERMISSIONS, USER_PERMISSIONS } from 'constants/rules';
import FieldPhones from 'containers/FieldPhones';
import FieldRelatedPeople from 'containers/FieldRelatedPeople';
import { Wrapper, Box, BoxLeft, BoxRight } from './styles';

const propTypes = {
  personId: PropTypes.object,
  groups_id: PropTypes.any,
  stateId: PropTypes.string,
  cityId: PropTypes.string,
  config: PropTypes.shape({
    checkGroupOwner: PropTypes.bool,
  }),
  typePerson: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  peopleGroups: PropTypes.arrayOf(PropTypes.object),
  realtors: PropTypes.arrayOf(PropTypes.object),
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  changeFieldValue: PropTypes.func.isRequired,
};
const defaultProps = {
  config: {
    checkGroupOwner: false,
  },
  personId: null,
  groups_id: null,
  stateId: null,
  cityId: null,
  typePerson: null,
  realtors: [{}],
  peopleGroups: [{}],
};

class PersonForm extends React.Component {
  constructor() {
    super();

    this.fieldName = null;
  }
  componentDidMount() {
    // Pega as origens de captação
    this.props.getReceptionSources();

    setTimeout(() => {
      this.fieldName.focus();
    }, 1000);

    Tooltip.rebuild();
  }

  get canEdit() {
    const { person } = this.props;
    const hasPermissions = person && person.permissions;

    if (hasPermissions) {
      return person.permissions.includes(PERSON_PERMISSIONS.EDIT);
    }

    return true;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { config, peopleGroups } = this.props;
    const { peopleGroups: nextPeopleGroups } = nextProps;

    if (config?.checkGroupByName && peopleGroups !== nextPeopleGroups) {
      return nextPeopleGroups.forEach((group) => {
        if (group.name === config?.checkGroupByName) {
          this.props.change('groups_id', [group.id]);
        }
      });
    }

    if (config.checkGroupOwner && peopleGroups !== nextPeopleGroups) {
      return nextPeopleGroups.forEach((group) => {
        if (group.is_owner) {
          this.props.change('groups_id', [group.id]);
        }
      });
    }
  }

  isLegalPerson = () => {
    const { typePerson } = this.props;
    return typePerson === 2 || typePerson === '2';
  };

  handleChangeTypePerson = (e) => {
    const { changeFieldValue } = this.props;

    changeFieldValue('cpf_cnpj', null);
    changeFieldValue('rg_ie', null);

    if (e.target.value === 2 || e.target.value === '2') {
      changeFieldValue('spouse_name', null);
      changeFieldValue('spouse_cpf', null);
    }
  };

  handleNewType = (option) => {
    const { addGroup, groups_id, changeFieldValue } = this.props;
    addGroup(option.name).then((newOption) => {
      const value = groups_id || [];
      changeFieldValue('groups_id', [...value, newOption.id]);
    });
  };

  handleNewResource = (option) => {
    const { changeFieldValue } = this.props;

    this.props.addReceptionSource(option.name).then((newOption) => {
      changeFieldValue('reception_source_id', newOption.id);
    });
  };

  handleChangeCountries = (el, value) => {
    if (value) {
      this.props.getStates(value);
    }
    this.props.changeFieldValue('state_id', '');
    this.props.changeFieldValue('city_id', '');
    this.props.changeFieldValue('neighborhood_id', '');
  };

  handleChangeStates = (el, value) => {
    if (value) {
      this.props.getCities(value);
    }
    this.props.changeFieldValue('city_id', '');
    this.props.changeFieldValue('neighborhood_id', '');
  };

  handleChangeCities = (el, value) => {
    if (value) {
      this.props.getNeighborhoods(value);
    }

    this.props.changeFieldValue('neighborhood_id', '');
  };

  // TODO MELHORAR
  handleBlurEmail = (el, value) => {
    if (!value) return null;

    return peopleService
      .getAll({ filter: { email: value }, include: 'user' })
      .then(({ data: people }) => {
        if (people.length > 0) {
          this.props.setHasPerson(people[0]);
        }
      });
  };

  // TODO MELHORAR
  handleBlurCPF_CNPJ = (el, value) => {
    if (!value) return null;

    return peopleService
      .getAll({ filter: { cpf_cnpj: value }, include: 'user' })
      .then(({ data: people }) => {
        if (people.length > 0) {
          this.props.setHasPerson(people[0]);
        }
      });
  };

  handleBlurCellphoneNumber = (el, value) => {
    if (!value || this.props.isEditting) return null;

    return peopleService
      .getAll({ filter: { cellphone_number: value }, include: 'user' })
      .then(({ data: people }) => {
        if (people.length > 0) this.props.setHasPerson(people[0]);
      });
  };

  setLocation = (data) => {
    const { change, getStates, getCities, getNeighborhoods } = this.props;

    const cityId = _.get(data, 'city.id', null);
    const countryId = _.get(data, 'country.id', null);
    const neighborhoodId = _.get(data, 'neighborhood.id', null);
    const stateId = _.get(data, 'state.id', null);
    const streetAddress = data.street_address;

    if (countryId) {
      getStates(countryId);
      change('country_id', countryId);
    }

    if (stateId) {
      getCities(stateId);
      change('state_id', stateId);
    }

    if (cityId) {
      getNeighborhoods(cityId);
      change('city_id', cityId);
    }

    if (streetAddress) change('address_street', streetAddress);

    if (neighborhoodId) change('neighborhood_id', neighborhoodId);
  };

  fetchCEP = (cep) => {
    return cepsService.getLocation({ cep }).then(({ data }) => {
      this.setLocation(data);
    });
  };

  handleOpenModalAddNeighborhood = () => {
    const { stateId, countryId, cityId, formValues } = this.props;

    this.props.openModalAddNeighborhood({
      initialValues: {
        country_id: countryId,
        state_id: stateId,
        city_id: cityId,
      },
      onClose: (res) => {
        const countryId = res?.data?.country?.id;
        const stateId = res?.data?.state?.id;
        const cityId = res?.data?.city?.id;
        const neighborhoodId = res?.data?.id;

        setTimeout(() => {
          this.setLocation(res.data);
        }, 500);

        this.props.openModalPersonAdd(
          {
            ...formValues,
            country_id: countryId,
            state_id: stateId,
            city_id: cityId,
            neighborhood_id: neighborhoodId,
          },
          null,
          {
            formType: 'PersonForm',
          }
        );
      },
    });
  };

  handleOpenModalAddCity = () => {
    const { stateId, countryId, cityId, formValues } = this.props;

    this.props.openModalAddCity({
      initialValues: {
        country_id: countryId,
        state_id: stateId,
        city_id: cityId,
      },
      onClose: (res) => {
        const countryId = res?.data?.state?.country_id;
        const stateId = res?.data?.state?.id;
        const cityId = res?.data?.first_neighborhood?.city_id;
        const neighborhoodId = res?.data?.first_neighborhood?.id;

        setTimeout(() => {
          this.setLocation({
            country: { id: countryId },
            state: { id: stateId },
            city: { id: cityId },
            neighborhood: { id: neighborhoodId },
          });
        }, 500);

        this.props.openModalPersonAdd(
          {
            ...formValues,
            country_id: countryId,
            state_id: stateId,
            city_id: cityId,
          },
          null,
          {
            formType: 'PersonForm',
          }
        );
      },
    });
  };

  handleChangeCep = (e, value, prevValue) => {
    if (!value || value === prevValue) return false;
    if (value.length === 9) {
      this.fetchCEP(value);
    }
    return false;
  };

  render() {
    const {
      person,
      isLoading,
      isEditting,
      stateId,
      countryId,
      cityId,
      peopleGroups,
      typePerson,
      // realtors,
      countries,
      states,
      cities,
      neighborhoods,
      isFetchingCountries,
      isFetchingStates,
      isFetchingCities,
      isFetchingNeighborhoods,
      submitting,
      handleSubmit,
      handleClose,
      receptionSources,
    } = this.props;

    return !this.canEdit ? (
      <ModalTemplate
        title="Sem permissão para editar esse cliente"
        handleClose={handleClose}
      />
    ) : (
      <ModalTemplate
        isLoading={isLoading}
        handleClose={handleClose}
        renderHeader={() => (
          <header className="Modal__header Modal__header--custom h-flex h-flex--center-center">
            <h4 className="Modal__title">
              {isEditting ? 'Editar Cliente' : 'Cadastrar Cliente'}
            </h4>
            <div className="h-flex__cell h-flex__cell--grow" />
            <div className="h-flex Form--inline">
              {!isEditting || !person.user_id ? (
                <FieldSelectUser
                  label="Corretor responsável"
                  style={{ width: '220px' }}
                />
              ) : (
                <Can
                  run={PERSON_PERMISSIONS.CHANGE_REALTOR}
                  permissions={person.permissions}
                >
                  <FieldSelectUser
                    label="Corretor responsável"
                    style={{ width: '220px' }}
                  />
                </Can>
              )}
            </div>
          </header>
        )}
      >
        <form onSubmit={handleSubmit}>
          <Wrapper>
            <BoxLeft>
              <Row>
                <Can
                  run={USER_PERMISSIONS.EDIT_PEOPLE_GROUPS}
                  yes={() => (
                    <Field
                      xs={3}
                      required
                      autoFocus
                      creatable
                      name="groups_id"
                      label="Categoria"
                      options={peopleGroups}
                      component={SelectMultiple}
                      onNewOptionClick={this.handleNewType}
                      valueKey="id"
                      labelKey="name"
                      parse={parseSelect('id')}
                    />
                  )}
                  no={() => (
                    <Field
                      xs={3}
                      required
                      autoFocus
                      name="groups_id"
                      label="Categoria"
                      options={peopleGroups}
                      component={SelectMultiple}
                      valueKey="id"
                      labelKey="name"
                      parse={parseSelect('id')}
                    />
                  )}
                />

                <Can
                  run={USER_PERMISSIONS.EDIT_RECEPTION_SOURCES}
                  yes={() => (
                    <Field
                      required
                      xs={3}
                      creatable
                      multi={false}
                      autoFocus
                      name="reception_source_id"
                      label="Origem da captação"
                      options={receptionSources}
                      component={SelectMultiple}
                      onNewOptionClick={this.handleNewResource}
                      valueKey="id"
                      labelKey="name"
                      parse={parseSelect('id')}
                    />
                  )}
                  no={() => (
                    <Field
                      required
                      xs={3}
                      multi={false}
                      autoFocus
                      name="reception_source_id"
                      label="Origem da captação"
                      options={receptionSources}
                      component={SelectMultiple}
                      valueKey="id"
                      labelKey="name"
                      parse={parseSelect('id')}
                    />
                  )}
                />

                <Field
                  xs={2}
                  name="type"
                  label="Tipo de cliente"
                  component={Select}
                  options={TYPE_PERSONS}
                  placeholder={null}
                  onChange={this.handleChangeTypePerson}
                />
                <Field
                  xs={2}
                  disabled={!typePerson}
                  name="cpf_cnpj"
                  label={this.isLegalPerson() ? 'CNPJ' : 'CPF'}
                  component={Input}
                  normalize={
                    this.isLegalPerson() ? normalizeCnpj : normalizeCpf
                  }
                  onBlur={this.handleBlurCPF_CNPJ}
                />
                <Field
                  xs={2}
                  disabled={!typePerson}
                  name="rg_ie"
                  label={this.isLegalPerson() ? 'I.E' : 'RG'}
                  component={Input}
                />

                <Field
                  refName={(ref) => {
                    this.fieldName = ref;
                  }}
                  xs={3}
                  required
                  name="name"
                  label="Nome"
                  component={Input}
                />

                <Field
                  xs={3}
                  type="email"
                  name="email"
                  label="E-mail"
                  component={Input}
                  onBlur={this.handleBlurEmail}
                />
                <Field
                  xs={3}
                  name="birth_date"
                  label="Data de nascimento"
                  component={Input}
                  format={normalizeDate}
                />

                <Field
                  xs={3}
                  type="text"
                  name="profession"
                  label="Profissão"
                  component={Input}
                />
              </Row>

              <Row>
                <Field
                  xs={2}
                  name="address_cep"
                  label="CEP"
                  component={Input}
                  normalize={normalizeCep}
                  onChange={this.handleChangeCep}
                />
                <Field
                  multi={false}
                  xs={2}
                  name="country_id"
                  label="País"
                  component={SelectMultiple}
                  options={countries}
                  valueKey="id"
                  labelKey="name"
                  onChange={this.handleChangeCountries}
                  parse={parseSelect('id')}
                  isLoading={isFetchingCountries}
                />
                <Field
                  multi={false}
                  xs={2}
                  name="state_id"
                  label="UF"
                  component={SelectMultiple}
                  disabled={!countryId}
                  options={states}
                  valueKey="id"
                  labelKey="name"
                  onChange={this.handleChangeStates}
                  parse={parseSelect('id')}
                  isLoading={isFetchingStates}
                />
                <Field
                  multi={false}
                  xs={3}
                  name="city_id"
                  label={
                    <>
                      Cidade{' '}
                      <span
                        onClick={this.handleOpenModalAddCity}
                        className="h-link"
                      >
                        (Cadastrar nova)
                      </span>
                    </>
                  }
                  component={SelectMultiple}
                  disabled={!stateId}
                  options={cities}
                  valueKey="id"
                  labelKey="name"
                  noResultsText="Nenhuma cidade encontrada"
                  onChange={this.handleChangeCities}
                  parse={parseSelect('id')}
                  isLoading={isFetchingCities}
                />
                <Field
                  multi={false}
                  xs={3}
                  name="neighborhood_id"
                  label={
                    <>
                      Bairro{' '}
                      <span
                        onClick={this.handleOpenModalAddNeighborhood}
                        className="h-link"
                      >
                        (Cadastrar novo)
                      </span>
                    </>
                  }
                  options={neighborhoods}
                  valueKey="id"
                  labelKey="name"
                  component={SelectMultiple}
                  disabled={!(!!stateId && !!cityId)}
                  parse={parseSelect('id')}
                  isLoading={isFetchingNeighborhoods}
                />

                <Field
                  xs={4}
                  name="address_street"
                  label="Logradouro"
                  component={Input}
                />
                <Field
                  xs={3}
                  name="address_number"
                  label="Número"
                  component={Input}
                />
                <Field
                  xs={5}
                  name="address_complement"
                  label="Complemento"
                  component={Input}
                />
              </Row>
              {!isEditting && (
                <Row>
                  <Field
                    growOnFocus
                    height={83}
                    growHeight={110}
                    xs={12}
                    label={
                      <>
                        Anotação{' '}
                        <MdHelp data-tip="Visualize as anotações <br/> na aba anotações na vida do cliente" />
                      </>
                    }
                    name="observation"
                    component={Textarea}
                    style={{ marginBottom: '15px' }}
                  />
                </Row>
              )}
              <ModalFooter
                style={{
                  position: 'sticky',
                  bottom: '0',
                  padding: '20px',
                  margin: '-5px -18px -20px',
                  background: '#fff',
                }}
              >
                <Button color="white" colorText="primary" onClick={handleClose}>
                  Cancelar
                </Button>
                <span className="h-flex__cell--grow" />
                <Button type="submit" color="success" disabled={submitting}>
                  {isEditting ? 'Editar' : 'Cadastrar'}
                </Button>
              </ModalFooter>
            </BoxLeft>
            <BoxRight>
              <Box>
                <FieldPhones
                  person={person}
                  onHasPhone={({ person: currentPerson }) => {
                    this.props.setHasPerson(currentPerson);
                  }}
                />
              </Box>
              <Box>
                <FieldRelatedPeople />
              </Box>
            </BoxRight>
          </Wrapper>
        </form>
      </ModalTemplate>
    );
  }
}

PersonForm.defaultProps = defaultProps;
PersonForm.propTypes = propTypes;

const selector = formValueSelector('personForm');

const mapStateToProps = (state, ownProps) => {
  return {
    formValues: getFormValues('personForm')(state),
    groups_id: selector(state, 'groups_id'),
    typePerson: selector(state, 'type'),
    stateId: selector(state, 'state_id'),
    countryId: selector(state, 'country_id'),
    cityId: selector(state, 'city_id'),
    neighborhoodId: selector(state, 'neighborhood_id'),
    receptionSourceId: selector(state, 'reception_source_id'),
    peopleGroups: state.peopleGroups.groups,
    realtors: state.realtors.realtors,
    initialValues: { ...state.modalPersonAdd.formData, ...ownProps.person },
    countries: locationSelectors.countriesSelector(state),
    isFetchingCountries: locationSelectors.countriesIsFetchingSelector(state),
    states: locationSelectors.statesSelector(state),
    isFetchingStates: locationSelectors.statesIsFetchingSelector(state),
    cities: locationSelectors.citiesSelector(state),
    isFetchingCities: locationSelectors.citiesIsFetchingSelector(state),
    neighborhoods: locationSelectors.neighborhoodsSelector(state),
    isFetchingNeighborhoods:
      locationSelectors.neighborhoodsIsFetchingSelector(state),
    receptionSources: receptionSourcesSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  addGroup: (typeName) => dispatch(addGroup(typeName)),
  changeFieldValue: (field, value) => {
    dispatch(change('personForm', field, value));
  },
  getStates: (country_id) => dispatch(locationThunks.getStates({ country_id })),
  getCities: (state_id) => dispatch(locationThunks.getCities({ state_id })),
  getNeighborhoods: (city_id) =>
    dispatch(locationThunks.getNeighborhoods({ city_id })),
  getReceptionSources: (params) => dispatch(getReceptionSources(params)),
  addReceptionSource: (name) => dispatch(addReceptionSource(name)),
  openModalAddCity: (...params) => dispatch(openModalAddCity(...params)),
  openModalAddNeighborhood: (...params) =>
    dispatch(openModalAddNeighborhood(...params)),
  openModalPersonAdd: (...params) => dispatch(openModalPersonAdd(...params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'personForm',
    validate,
    enableReinitialize: true,
  })(PersonForm)
);
