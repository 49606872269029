// Widgets.js
import Alert from 'react-s-alert';
import msg from '../lib/msg';
import { createModule } from '../lib/reducer-helpers';
import * as user from '../services/user';
import { receiveUser } from './login';
import { closeModal } from './modal';
import { addUser, alterUser } from './users';

const createAction = createModule('modalUser');

// Actions
const REQUEST_INITIAL_VALUES = createAction('REQUEST_VALUES');
const RECEIVE_INITIAL_VALUES = createAction('RECEIVE_VALUES');
const FAILED_INITIAL_VALUES = createAction('FAILED');

// Initial State
const initialState = {
  formData: {},
  meta: {
    isFetching: false,
  },
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_INITIAL_VALUES: {
      return {
        ...state,
        meta: {
          ...state.meta,
          isFetching: true,
        },
      };
    }
    case RECEIVE_INITIAL_VALUES: {
      return {
        ...state,
        formData: action.formData,
        meta: {
          ...state.meta,
          isFetching: false,
        },
      };
    }
    case FAILED_INITIAL_VALUES:
      return initialState;
    default:
      return state;
  }
}

// Action Creators
export function requestInitialValues() {
  return { type: REQUEST_INITIAL_VALUES };
}

export function receiveInitialValues(formData) {
  return { type: RECEIVE_INITIAL_VALUES, formData };
}

// side effects, only as applicable
// e.g. thunks, epics, etc
export const insertUser = (values) => (dispatch) =>
  user.add(values).then(async ({ data }) => {
    Alert.success(msg.create(data), { timeout: 2000 });
    const { data: userData } = await user.getOne(data.id, {
      include: 'group',
    });
    addUser(userData)(dispatch);
    dispatch(closeModal());
    return data;
  });

export const updateUser = (values) => (dispatch, getState) =>
  user
    .updateWithoutPassword(values)
    .then(async ({ data }) => {
      const {
        login: {
          currentUser: { id },
        },
      } = getState();

      Alert.success(msg.update(data), { timeout: 2000 });
      const { data: userData } = await user.getOne(data.id, {
        include: 'group',
      });
      dispatch(alterUser(userData));

      if (data.id === id) {
        dispatch(receiveUser(userData));
      }
      dispatch(closeModal());
      return data;
    })
    .catch((res) => {
      throw res;
    });

export const getModalUserValues = (id) => async (dispatch, getState) => {
  // reseta os valores que vem do formulario
  dispatch(receiveInitialValues({}));

  // inicia o request indicando que esta procurando dados
  dispatch(requestInitialValues());

  // busca dados no banco
  if (id) {
    user.getOne(id).then(({ data }) => {
      dispatch(receiveInitialValues(data));
    });
  } else {
    const { login } = getState();

    let initialValues = {};

    initialValues = {
      ...initialValues,
      realtor: true,
      should_send_instructions: true,
      should_show_password: true,
    };

    if (login.currentUser.admin) {
      initialValues = {
        ...initialValues,
        admin: false,
      };
    }

    dispatch(receiveInitialValues(initialValues));
  }
};

export function handleFormSubmit(values) {
  return (dispatch, getState) => {
    const {
      modalUser: { formData },
      modal: {
        modalProps: { callBack },
      },
    } = getState();

    if (formData.id) {
      return updateUser(values)(dispatch, getState).then((data) => {
        if (callBack) callBack(data, dispatch, getState);
      });
    }

    return insertUser(values)(dispatch, getState).then((data) => {
      if (callBack) callBack(data, dispatch, getState);
    });
  };
}
